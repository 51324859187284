import instance from "../../Config/axios";
import { setAlert } from "./AlertAction";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const {
  ADD_ORDERS_REQUEST,
  ADD_ORDERS_SUCCESS,
  ADD_ORDERS_FAIL,
  GET_ORDERS_REQUEST,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  UPDATE_ORDERS_STATUS_REQUEST,
  UPDATE_ORDERS_STATUS_SUCCESS,
  UPDATE_ORDERS_STATUS_FAIL,
  UPDATE_ORDERS_ITEM_STATUS_FAIL,
  UPDATE_ORDERS_ITEM_STATUS_REQUEST,
  UPDATE_ORDERS_ITEM_STATUS_SUCCESS,
} = require("../constants/orderConstant");

const CreateOrder = (orderPayload, sendPdfFunc) => async (dispatch) => {
  try {
    dispatch({ type: ADD_ORDERS_REQUEST, payload: orderPayload });
    // create product
    console.log(orderPayload, "form Action");

    const orderCreate = await instance.post(
      "/orders/create-order",
      orderPayload
    );
    console.log(orderCreate, "response");
    await sendPdfFunc(orderCreate.data.data.orderCreated._id);
    const orderEmail = await instance.post(
      "/orders/send-order-email/" + orderCreate.data.data.orderCreated._id
    );

    if(!orderPayload.isDrafted) {
      setAlert(
        "Thank you!!!",
        "Your order has been confirmed!",
        "success"
      );
    }

    dispatch({
      type: ADD_ORDERS_SUCCESS,
      payload: orderCreate.data,
      success: true,
    });
    // window.location.reload();
  } catch (error) {
    console.log(error, "error");
    setAlert("Oops!", "Something wrong, Please try later!", "error");

    dispatch({ type: ADD_ORDERS_FAIL, payload: error.response });
  }
};

const GetOrderAction = (Page, Limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDERS_REQUEST });
    let userInfoData = cookies.get("userData");
    if (userInfoData) {
      const { isAdmin } = userInfoData;
      let data = null;
      if (isAdmin) {
        data = await instance.get(`/orders?page=${Page}&perPage=${Limit}`);
      } else {
        // ********************** NO IMPLEMENTATION FOR AGENCY USER YET **********************
        // data = await instance.post(
        //   `/api/get-product?page=${Page}&perPage=${Limit}`
        // );
      }
      console.log(data, "data is here");
      dispatch({
        type: GET_ORDERS_SUCCESS,
        payload: data?.data?.data?.orders,
        success: true,
      });
    } else {
      setAlert("Oops!", "Something wrong, Please try later!", "error");
    }
  } catch (error) {
    setAlert("Oops!", "Something wrong, Please try later!", "error");
    dispatch({ type: GET_ORDERS_FAIL, payload: error.response });
  }
};

const UpdateOrderStatus =
  ({ orderId, status }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ORDERS_STATUS_REQUEST });
      console.log(orderId, status, "order to chage");
      const data = await instance.post(`/api/update-admin-order-status`, {
        orderId,
        status,
      });
      console.log(data, "data from back");
      setAlert("Good job!", "Agency Status Changed Successfully!", "success");
      dispatch({
        type: UPDATE_ORDERS_STATUS_SUCCESS,
        payload: data.data,
        success: true,
      });
    } catch (error) {
      setAlert("Oops!", "Something wrong, Please try later!", "error");

      dispatch({ type: UPDATE_ORDERS_STATUS_FAIL, payload: error.response });
    }
  };

const UpdateOrderItemStatus =
  ({ orderId, statusToChange, status }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ORDERS_ITEM_STATUS_REQUEST });
      console.log(orderId, status, "order to chage");
      let data;
      if (statusToChange === "isBooked") {
        data = await instance.put(
          `/orders/update-order-booked-status/${orderId}`,
          {
            bookedStatus: status,
          }
        );
      } else {
        data = await instance.put(
          `/orders/update-order-confirmed-status/${orderId}`,
          {
            confirmedStatus: status,
          }
        );
      }
      console.log(data, "data from back");
      setAlert("Good job!", "Order Status Changed Successfully!", "success");
      dispatch(GetOrderAction());

      dispatch({
        type: UPDATE_ORDERS_ITEM_STATUS_SUCCESS,
        payload: data.data,
        success: true,
      });
    } catch (error) {
      setAlert("Oops!", "Something wrong, Please try later!", "error");

      dispatch({
        type: UPDATE_ORDERS_ITEM_STATUS_FAIL,
        payload: error.response,
      });
    }
  };

export {
  CreateOrder,
  GetOrderAction,
  UpdateOrderStatus,
  UpdateOrderItemStatus,
};
