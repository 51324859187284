import instance from "../../Config/axios";
import { setAlert } from "./AlertAction";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const {
  ADD_PRODUCTS_REQUEST,
  ADD_PRODUCTS_FAIL,
  ADD_PRODUCTS_SUCCESS,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_REQUEST,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_FAIL,
} = require("../constants/productConstant");

// ******************************* Add PRODUCTS ****************************************
const AddProductAction = (product, CImage) => async (dispatch) => {
  const coverImage = new FormData();
  const productImages = new FormData();

  try {
    dispatch({ type: ADD_PRODUCTS_REQUEST, payload: product });
    // create product
    console.log(product, "form Action");
    let productFormData = new FormData();
    productFormData.append("name", product.name);
    productFormData.append("volume", product.volume);
    productFormData.append("categoryId", product.categoryId);
    // productFormData.append("file", CImage);
    [...CImage].forEach((image, index) => {
      productFormData.append(`file`, image);
    });
    console.log(productFormData, "form Action datra");
    const productCreate = await instance.post(
      "/products/create-product",
      productFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    setAlert("Good job!", "Product Added Successfully!", "success");
    dispatch({
      type: ADD_PRODUCTS_SUCCESS,
      payload: productCreate.data,
      success: true,
    });
  } catch (error) {
    console.log(error, "error");
    setAlert("Oops!", "Something wrong, Please try later!", "error");

    dispatch({ type: ADD_PRODUCTS_FAIL, payload: error.response });
  }
};

const GetProductAction = (Page, Limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCTS_REQUEST });
    let userInfoData = cookies.get("userData");
    if (userInfoData) {
      const { isAdmin } = userInfoData;
      let data = null;
      if (isAdmin) {
        data = await instance.get(`/products?page=${Page}&perPage=${Limit}`);
      }
      console.log(data, "data is here");
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: data?.data?.data?.products,
        success: true,
      });
    } else {
      setAlert("Oops!", "Something wrong, Please try later!", "error");
    }
  } catch (error) {
    setAlert("Oops!", "Something wrong, Please try later!", "error");
    dispatch({ type: GET_PRODUCTS_FAIL, payload: error.response });
  }
};

const DeleteProduct = (productId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCTS_REQUEST });
    console.log(productId, "product to delete");
    const data = await instance.delete(`/products/${productId}`);
    console.log(data, "data from back");
    setAlert("Good job!", "Product deleted Successfully!", "successessfully");
    dispatch({
      type: DELETE_PRODUCTS_SUCCESS,
      payload: data.data,
      success: true,
    });
  } catch (error) {
    setAlert("Oops!", "Something wrong, Please try later!", "error");

    dispatch({ type: DELETE_PRODUCTS_FAIL, payload: error.response });
  }
};

export { AddProductAction, GetProductAction, DeleteProduct };
