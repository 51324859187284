import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PMCLogo from '../../../asset/image/sonicmovers-logo.webp';
import emailImg from '../../../asset/image/email.png';
import telephoneImg from '../../../asset/image/telephone.png';
import pinImg from '../../../asset/image/placeholder-2.png';
import borderImg from '../../../asset/image/border.png';
import contactImg from '../../../asset/image/contacts.png';
import { Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

export default function Header() {
	const currentYear = new Date().getFullYear();

	return (
		<>
			<Grid
				container
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
					paddingTop: '1em',
					paddingBottom: '2em',
					backgroundColor: '#323b4d',
				}}
				direction='column'>
				<Grid
					item
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '10px',
					}}
					direction='column'>
					{/* <img
            src={PMCLogo}
            style={{ maxWidth: "200px", cursor: "pointer" }}
            onClick={() =>
              (window.location.href = "https://professionalmovingcompany.nl/")
            }
          /> */}
					<Typography
						variant='h2'
						style={{
							color: '#fff',
							fontSize: '36px',
							marginTop: '1em',
							fontFamily: 'Raleway, sans-serif',
						}}
						gutterBottom>
						Book your move online today!
					</Typography>
					<img
						src={borderImg}
						style={{ maxWidth: '200px' }}
					/>
					{/* <img src={contactImg} /> */}
					<a
						href='mailto:info@sonicmovers.nl'
						style={{ textDecoration: 'none' }}>
						<Typography
							variant='h2'
							style={{
								color: '#fff',
								fontSize: '18px',
								marginTop: '1em',
								fontFamily: 'Raleway, sans-serif',
								display: 'flex',
								gap: '10px',
								wordBreak: 'break-all',
								cursor: 'pointer',
							}}
							gutterBottom
							// onClick={() => {
							//   window.open("https://professionalmovingcompany.nl/", "_blank");
							// }}
						>
							<MailOutlineIcon /> info@sonicmovers.nl
						</Typography>
					</a>
					<a
						href='tel:+31 623847001'
						style={{ textDecoration: 'none' }}>
						<Typography
							variant='h2'
							style={{
								color: '#fff',
								fontSize: '18px',
								marginTop: '1em',
								fontFamily: 'Raleway, sans-serif',
								display: 'flex',
								gap: '10px',
								cursor: 'pointer',
							}}
							gutterBottom
							// onClick={() => {
							//   window.open("https://professionalmovingcompany.nl/", "_blank");
							// }}
						>
							<PhoneIcon />
							+31 623847001
						</Typography>
					</a>
				</Grid>
				<Grid
					item
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					direction='column'>
					<hr
						style={{
							width: '90%',
							display: 'block',
							height: '1px',
							border: '0',
							borderTop: '1px solid #46525e',
							margin: '1em 0',
							padding: 0,
						}}
					/>
					<Typography
						variant='p'
						style={{
							color: '#fff',
							fontSize: '14px',
							marginTop: '1em',
							fontFamily: 'Raleway, sans-serif',
						}}
						gutterBottom>
						Copyright © {currentYear} Sonic Movers – All Rights Reserved
					</Typography>
				</Grid>
			</Grid>
		</>
	);
}
