import {
  ADD_ORDERS_REQUEST,
  ADD_ORDERS_SUCCESS,
  ADD_ORDERS_FAIL,
  GET_ORDERS_REQUEST,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  UPDATE_ORDERS_STATUS_REQUEST,
  UPDATE_ORDERS_STATUS_SUCCESS,
  UPDATE_ORDERS_STATUS_FAIL,
  UPDATE_ORDERS_ITEM_STATUS_REQUEST,
  UPDATE_ORDERS_ITEM_STATUS_SUCCESS,
  UPDATE_ORDERS_ITEM_STATUS_FAIL,
} from "../constants/orderConstant";

// ************************************ Get orders *************************************
function AddOrdersReducer(state = { orders: {} }, action) {
  switch (action.type) {
    case ADD_ORDERS_REQUEST:
      return { loading: true, orders: {} };
    case ADD_ORDERS_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case ADD_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function GetOrdersReducer(state = { orders: {} }, action) {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return { loading: true, orders: {} };
    case GET_ORDERS_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case GET_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function UpdateOrdersStatusReducer(state = { orders: {} }, action) {
  switch (action.type) {
    case UPDATE_ORDERS_STATUS_REQUEST:
      return { loading: true, orders: {} };
    case UPDATE_ORDERS_STATUS_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case UPDATE_ORDERS_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function UpdateOrdersItemStatusReducer(state = { orders: {} }, action) {
  switch (action.type) {
    case UPDATE_ORDERS_ITEM_STATUS_REQUEST:
      return { loading: true, orders: {} };
    case UPDATE_ORDERS_ITEM_STATUS_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case UPDATE_ORDERS_ITEM_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  AddOrdersReducer,
  GetOrdersReducer,
  UpdateOrdersStatusReducer,
  UpdateOrdersItemStatusReducer,
};
