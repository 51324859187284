//**********user Auth***********\
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

// ***********************User logout********************
export const USER_LOGOUT = 'USER_LOGOUT';

// ***********************User Register********************
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

// ***********************User Reset Password********************
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL';

// ***********************User Reset Password********************
export const USER_STORED_PASSWORD_SUCCESS = 'USER_STORED_PASSWORD_SUCCESS';
export const USER_STORED_PASSWORD_REQUEST = 'USER_STORED_PASSWORD_REQUEST';
export const USER_STORED_PASSWORD_FAIL = 'USER_STORED_PASSWORD_FAIL';
