import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Popover from "@material-ui/core/Popover";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
    marginBottom: "1em",
    maxWidth: "1200px",
    flexGrow: 1,
    backgroundColor: "#c12b2d",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: "14px",
    fontFamily: "Raleway, Sans-serif",
    paddingRight: "2em",
    fontWeight: "medium",
    cursor: "pointer",
    "&:hover": {
      color: "#8b041d",
    },
    // flexGrow: 1,
  },
  //   popover: {
  //     pointerEvents: "none",
  //   },
  paper: {
    marginTop: "1.5em",
    padding: theme.spacing(1),
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    console.log("i am running");
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    // <div className={classes.root}>
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          {/* <MenuIcon /> */}
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          HOME
        </Typography>
        <Typography variant="h6" className={classes.title}>
          SERVICES
        </Typography>
        <Typography variant="h6" className={classes.title}>
          OUR TARRIFS
        </Typography>
        <Typography variant="h6" className={classes.title}>
          OUR LOCATIONS
        </Typography>
        <Typography variant="h6" className={classes.title}>
          BLOG
        </Typography>
        <Typography variant="h6" className={classes.title}>
          OUR TERMS
        </Typography>
        <Typography variant="h6" className={classes.title}>
          CONTACT US
        </Typography>
        {/* <Typography
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          //   onMouseLeave={handlePopoverClose}
          variant="h6"
          className={classes.title}
        >
          SERVICES
        </Typography> */}
        <Popover
          id="mouse-over-popover"
          //   onMouseEnter={handlePopoverOpen}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          elevation={0}
          PaperProps={{ square: true }}
        >
          <Typography>I use Popover.</Typography>
        </Popover>
      </Toolbar>
    </AppBar>
    // </div>
  );
}
