import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Spacer from "../../../Helpers/Spacer";
import { generateStylesInformation } from "../../../Helpers/StylesGenerator";
const prettyMetric = require("pretty-metric");

const Blue = "#21242e";
const Orange = "#FFBA60";
const lightBlue = `#1976D2`;
const Grey = "#616161";

const useStyle = makeStyles((theme) => generateStylesInformation(theme));
export default function Review({ handlers }) {
  const classes = useStyle();
  const matchesSm = useMediaQuery("(min-width:600px)");
  const {
    orderCalculations,
    selectedPickupLocation,
    selectedDeliveryLocation,
    addressDistance,
    totalDistance,
    totalStairs,
    selectedItemsVolume,
    selectedItems,
    formData,
    selectedDate,
    selectedTimeslot,
    selectedPickupAddress,
    selectedDeliveryAddress,
  } = handlers;
  const {
    totalOrderCost,
    totalKMCost,
    totalWorkersCost,
    totalStairsCost,
    totalTruckCost,
    totalCargoCost,
  } = orderCalculations;
  console.log(orderCalculations, "items selected");
  const {
    name,
    email,
    mobile,
    cargo,
    numberOfWorkers,
    numberOfStairsFirstAddress,
    numberOfStairsSecondAddress,
  } = formData;
  return (
    <Grid container direction="row" >
      <Grid item container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.inputContainer}
        >
          <Grid item container className={classes.flexChangeOnResponsive}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: "500" }}
                gutterBottom
              >
                Contact Information
              </Typography>
            </Grid>
            {/* <Spacer margin={matchesSm && 25 } /> */}
            <Grid
              item
              container
              xs={12}
              sm={4}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>Name: {name}</p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid
              item
              container
              xs={12}
              sm={4}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>Email: {email}</p>
            </Grid>
            <Spacer margin={matchesSm && 25} />

            <Grid item container xs={12} sm={4}>
              <p style={{ fontSize: "16px" }}>Mobile: {mobile}</p>
            </Grid>
          </Grid>

          {matchesSm && (
            <Grid item container direction="column">
              <Spacer margin={matchesSm && 10} />
              <Grid item xs={12}>
                <Divider style={{ backgroundColor: "rgb(0 0 0 / 23%)" }} />
              </Grid>
              {/* <Spacer margin={matchesSm && 10} /> */}
            </Grid>
          )}
          <Grid item container style={{marginTop: '25px'}} className={classes.flexChangeOnResponsive}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: "500" }}
                gutterBottom
              >
                Move Information
              </Typography>
            </Grid>
            {/* <Spacer margin={matchesSm ? 25 : 10} /> */}
            {/* <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>Google pickup</p>
            </Grid>
            <Spacer margin={10} />
            <Grid item container xs={12} sm={6}>
              <p style={{ fontSize: "16px" }}>Google delivery</p>
            </Grid>
            <Spacer margin={matchesSm ? 30 : 10} /> */}
            <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>
                Pickup Address: {selectedPickupAddress}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid item container xs={12} sm={6}>
              <p style={{ fontSize: "16px" }}>
                Delivery Address: {selectedDeliveryAddress}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>
                Stairs in Pickup Address:{" "}
                {numberOfStairsFirstAddress == -1
                  ? 0
                  : numberOfStairsFirstAddress}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid item container xs={12} sm={6}>
              <p style={{ fontSize: "16px" }}>
                Stairs in Delivery Address:{" "}
                {numberOfStairsSecondAddress == -1
                  ? 0
                  : numberOfStairsSecondAddress}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>
                No. of Workers: {numberOfWorkers}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />

            <Grid item container xs={12} sm={6}>
              <p style={{ fontSize: "16px" }}>
                Do you need Moving Lifter: {cargo}
              </p>
            </Grid>
          </Grid>
          {/* <Grid item container direction="column">
            <Spacer margin={10} />
            <Grid item xs={12}>
              <Divider style={{ backgroundColor: "rgb(255 255 255 / 23%)" }} />
            </Grid>
            <Spacer margin={10} />
          </Grid> */}
          <Grid item container className={classes.flexChangeOnResponsive}>
            {/* <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: "500" }}
                gutterBottom
              >
                Order Information
              </Typography>
            </Grid>
            <Spacer margin={matchesSm ? 25 : 10} /> */}
            <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>
                Distance (pickup - destination):{" "}
                {/* {(addressDistance / 1000).toFixed(2)} Km */}
                {prettyMetric(addressDistance).humanize()}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid item container xs={12} sm={6}>
              {/* <p style={{ fontSize: "16px" }}>
                Total Move Distance: {prettyMetric(totalDistance).humanize()}
              </p> */}
              <p style={{ fontSize: "16px" }}>
                Total Volume: {selectedItemsVolume.toFixed(1)} Cubic Meter
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            {/* <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
            >
              <p style={{ fontSize: "16px" }}>
                Total Volume: {selectedItemsVolume} Cubic Meter
              </p>
            </Grid>
            <Spacer margin={10} />
            <Grid item container xs={12} sm={6}>
              <p style={{ fontSize: "16px" }}>
                Total Order Amount: {totalOrderCost.toFixed(2)}
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} /> */}
            <Grid
              item
              container
              xs={12}
              sm={6}
              className={classes.marginRightOnResponsive}
              style={{ alignItems: "baseline" }}
            >
              <p style={{ fontSize: "16px", marginRight: "5px" }}>
                Date of Move: {new Date(selectedDate).toLocaleDateString()},{" "}
                {selectedTimeslot == 1
                  ? "Morning (7 - 9)"
                  : selectedTimeslot == 2
                  ? "Afternoon (12 - 3)"
                  : "Evening (5 - 7)"}
              </p>
              {"  "}
              <p style={{ fontSize: "12px" }}>(Exact date to be agreed)</p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid item container xs={12} sm={6}>
              {/* <p style={{ fontSize: "16px" }}>
                Time of Move:{" "}
                {selectedTimeslot === 1
                  ? "Morning"
                  : selectedTimeslot === 2
                  ? "Afternoon"
                  : "Evening"}
              </p> */}
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                Total Order Amount: € {Math.floor(totalOrderCost.toFixed(2))} ex
                BTW
              </p>
            </Grid>
            <Spacer margin={matchesSm && 25} />
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "600" }}>Name</TableCell>
                      <TableCell style={{ fontWeight: "600" }} align="right">
                        Quantity&nbsp;
                      </TableCell>
                      <TableCell style={{ fontWeight: "600" }} align="right">
                        Volume&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedItems.map((row) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">
                            {(row.volume * row.quantity).toFixed(1)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
