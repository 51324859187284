import { createTheme } from "@material-ui/core/styles";

const Blue = "#21242e";
const Orange = "#FFBA60";
const lightBlue = `#1976D2`;
const Grey = "#616161";

export default createTheme({
  palette: {
    common: {
      Blue: `${Blue}`,
      Orange: `${Orange}`,
      Gray: `${Grey}`,
    },
    primary: {
      main: `${Blue}`,
      contrastText: "#fff",
    },
    secondary: {
      main: `${lightBlue}`,
      contrastText: "#fff",
    },
  },
  typography: {
    sideBar: {
      fontFamily: "Arial",
      textTransform: "capitalize",
      fontSize: "0.875em",
    },
    tab: {
      fontFamily: "Poppins, sans-serif",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
    },
    h2: {
      fontFamily: "Roboto,Helvetica,Arial, sans-serif",
      fontSize: "20px",
      fontWeight: "700",
    },
    h3: {
      fontFamily: "Roboto,Helvetica,Arial, sans-serif",
      fontSize: "14px",

      lineHeight: 1,
    },
    h4: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Roboto,Helvetica,Arial, sans-serif",
      fontSize: "20px",
      // fontWeight: 'bold',
      // color: '#666666',
    },
    h6: {
      fontFamily: "Roboto,Helvetica,Arial, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: 1,
    },
    subtitle1: {
      color: Grey,
      fontSize: "1.25rem",
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: ".9rem",
      fontWeight: 300,
    },

    body1: {
      fontSize: "1.25rem",
      color: Grey,
      fontWeight: 300,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        // color: '#9999',
        fontSize: "14px",
      },
    },
    MuiInput: {
      root: {
        // color: '#909090',
        fontWeight: 300,
        fontSize: "12px",
      },
      underline: {
        "&:before": {
          // borderBottom: `1px solid ${Grey}`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          // borderBottom: `1px solid ${Grey}`,
        },
      },
    },
  },
});
