import React, { useEffect, useState } from "react";
import {
  Button,
  Fade,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../reduxState/aciton/authAction";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";

const useStyle = makeStyles((theme) => ({
  background: {
    color: "#e6e6e6",
    margin: 0,
  },
  Paper: {
    width: "520px",
    marginTop: "10em",
    color: "#f6f6f6",
    borderRadius: "17px",
    padding: "2em",
  },
  Heading: {
    marginTop: "1em",
    fontSize: "1.3em",
    fontFamily: "Poppins, sans-serif",
    color: `${theme.palette.common.Blue}`,
  },
  Link: { textDecoration: "none" },
  Button: {
    borderRadius: "20px",
    padding: "13px",
    backgroundColor: `${theme.palette.common.Blue}`,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login() {
  const classes = useStyle();
  let navigate = useNavigate();

  // ***************************************state *********************************************
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });
  const [validationHelper, setValidationHelper] = useState({
    numberHelper: "",
    passwordHelper: "",
  });

  const { email, password } = formData;
  const { numberHelper, passwordHelper } = validationHelper;

  // Snackbar
  const [openSanckbar, setOpenSnackbar] = useState({
    open: false,
    severity: "",
  });

  // *********************************OnChange handle**********************************************
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
    let valid;

    switch (e.target.name) {
      case "email":
        valid =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            e.target.value
          );
        if (!valid) {
          setValidationHelper({
            ...validationHelper,
            numberHelper: "Invalid Number",
          });
        } else {
          setValidationHelper({ ...validationHelper, numberHelper: "" });
        }
        break;
      default:
        break;
    }
  };

  // ***************************Redux State***************************
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, userInfo, error: errorSuccess } = userSignin;

  // ***************************Validation**************************

  const Validation = () => {
    let isError = true;

    if (email.length === 0) {
      isError = false;

      validationHelper.numberHelper = "Number is Required";
    }

    if (password.length === 0) {
      isError = false;
      validationHelper.passwordHelper = "Password is Required";
    }

    setValidationHelper({
      ...validationHelper,
    });

    return isError;
  };

  // *******************************onSubmit ******************************************************
  const onSubmit = (e) => {
    let err = Validation();

    if (err) {
      e.preventDefault();
      dispatch(userLogin(formData));
    }
  };

  // *************************************useEffect***********************************************
  useEffect(() => {
    if (userInfo) {
      console.log(userInfo, "userinfo");
      navigate("/dashboard/Main");
    }
  }, [userInfo]);

  // *************************************useEffect***********************************************
  useEffect(() => {
    if (errorSuccess) {
      setOpenSnackbar({
        open: true,
        severity: "error",
        message: errorSuccess ? errorSuccess : "Check you internet connection!",
      });
    }
  }, [errorSuccess]);

  return (
    <Grid item container className={classes.background} justify="center">
      <Grid
        item
        container
        component={Paper}
        elevation={3}
        className={classes.Paper}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justifyContent="center"
          className={classes.Heading}
        >
          <Typography variant="h4">Sign in To Sonic Movers Admin</Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: "2em" }}
        >
          <Grid item container justify="center">
            <TextField
              id="Email"
              variant="outlined"
              placeholder="Email"
              fullWidth
              inputProps={{
                style: {
                  padding: 13,
                  fontSize: "1.1rem",
                },
              }}
              name="email"
              value={email || ""}
              error={numberHelper.length !== 0}
              helperText={numberHelper}
              onChange={onChange}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: "2em" }}
          >
            <TextField
              type="password"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              placeholder="password"
              inputProps={{
                style: {
                  padding: 13,
                  fontSize: "1.1rem",
                },
              }}
              name="password"
              value={password || ""}
              error={passwordHelper.length !== 0}
              helperText={passwordHelper}
              onChange={onChange}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: "2em" }}
          >
            <Button
              onClick={onSubmit}
              fullWidth
              variant="contained"
              className={classes.Button}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        {/* <Grid item style={{ marginTop: "1em" }}>
          <Link to="/ForgetPasword" className={classes.Link}>
            <Typography
              variant="subtitle2"
              style={{ color: "#ff9326", fontWeight: 450 }}
            >
              Forget password ?
            </Typography>
          </Link>
        </Grid> */}
        {/* <Grid
          item
          container
          justifyContent="center"
          style={{ marginTop: "2em" }}
        >
          <Link to="/Register" className={classes.Link}>
            <Typography variant="h5">
              Don't have an account ?{" "}
              <span style={{ color: "#ff9326", fontWeight: 400 }}>
                {" "}
                Register
              </span>
            </Typography>
          </Link>
        </Grid> */}
      </Grid>

      {/* ********************************Snackbar ********************************* */}
      <Snackbar
        open={openSanckbar.open}
        autoHideDuration={6000}
        TransitionComponent={Fade}
        onClose={() => {
          setOpenSnackbar({ open: false });
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar({ open: false });
          }}
          severity={openSanckbar.severity}
        >
          {openSanckbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
export default Login;
