import instance from "../../Config/axios";
import { setAlert } from "./AlertAction";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_REQUEST,
} = require("../constants/settingConstant");

const GetSettingAction = (Page, Limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_SETTINGS_REQUEST });
    let userInfoData = cookies.get("userData");
    if (userInfoData) {
      const { isAdmin } = userInfoData;
      let data = null;
      if (isAdmin) {
        data = await instance.get(
          `/configurations?page=${Page}&perPage=${Limit}`
        );
      }
      console.log(data, "data is here");
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: data?.data?.data?.configurations,
        success: true,
      });
    } else {
      setAlert("Oops!", "Something wrong, Please try later!", "error");
    }
  } catch (error) {
    setAlert("Oops!", "Something wrong, Please try later!", "error");
    dispatch({ type: GET_SETTINGS_FAIL, payload: error.response });
  }
};

const UpdateSettingItemValue =
  ({ settingId, value }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SETTINGS_REQUEST });
      const data = await instance.put(
        `/configurations/update-configuration/${settingId}`,
        {
          value,
        }
      );
      console.log(data, "data from back");
      setAlert("Good job!", "Setting value Changed Successfully!", "success");
      dispatch(GetSettingAction());

      dispatch({
        type: UPDATE_SETTINGS_SUCCESS,
        payload: data.data,
        success: true,
      });
    } catch (error) {
      setAlert("Oops!", "Something wrong, Please try later!", "error");

      dispatch({
        type: UPDATE_SETTINGS_FAIL,
        payload: error.response,
      });
    }
  };

export { GetSettingAction, UpdateSettingItemValue };
