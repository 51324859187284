import instance from "../../Config/axios";
import { setAlert } from "./AlertAction";

const {
  ADD_USERS_REQUEST,
  ADD_USERS_FAIL,
  ADD_USERS_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  UPDATE_USERS_STATUS_SUCCESS,
  UPDATE_USERS_STATUS_REQUEST,
  UPDATE_USERS_STATUS_FAIL,
} = require("../constants/userConstant");

// ******************************* Add PRODUCTS ****************************************
const AddUsersAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: ADD_USERS_REQUEST, payload: user });
    // create user
    console.log(user, "form Action");

    const data = await instance.post("/api/create-agency-user", user);
    console.log(data, "data");

    setAlert("Good job!", "User Added Successfully!", "success");
    dispatch({ type: ADD_USERS_SUCCESS, payload: data, success: true });
  } catch (error) {
    setAlert("Oops!", "Something wrong, Please try later!", "error");

    dispatch({ type: ADD_USERS_FAIL, payload: error.response });
  }
};

// ******************************* Update USERS Status ****************************************
const UpdateUserStatus =
  ({ userId, checkedValueUpdated }) =>
  async (dispatch) => {
    const coverImage = new FormData();
    const productImages = new FormData();

    try {
      dispatch({ type: UPDATE_USERS_STATUS_REQUEST });

      const data = await instance.put(`/api/agency/update-status`, {
        userId,
        checkedValueUpdated,
      });
      console.log(data, "data from back");
      setAlert("Good job!", "Agency Status Changed Successfully!", "success");
      dispatch({
        type: UPDATE_USERS_STATUS_SUCCESS,
        payload: data,
        success: true,
      });
    } catch (error) {
      setAlert("Oops!", "Something wrong, Please try later!", "error");

      dispatch({ type: UPDATE_USERS_STATUS_FAIL, payload: error.response });
    }
  };

const GetUsersAction = (Page, Limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_USERS_REQUEST });
    const { data } = await instance.get(`/api/get-agency-list`);

    dispatch({ type: GET_USERS_SUCCESS, payload: data.data, success: true });
  } catch (error) {
    dispatch({ type: GET_USERS_FAIL, payload: error.response });
  }
};

export { AddUsersAction, GetUsersAction, UpdateUserStatus };
