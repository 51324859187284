import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../BreadCrum/BreadCrum";
import instance from "../../Config/axios";
import Table from "../Table/index";

import {
  GetUsersAction,
  UpdateUserStatus,
} from "../../reduxState/aciton/userAction";

const useStyles = makeStyles((theme) => ({
  root: { padding: "1rem" },
  headerFont: {
    fontSize: "1.1rem",
    fontFamily: "sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

function User() {
  const classes = useStyles();
  // ******************* useState **********************************
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  // let users = [];

  // ******************* Redux State********************************
  const dispatch = useDispatch();
  const getUser = useSelector((state) => state.getUserReducer);
  const { users } = getUser;

  const getUpdateStatus = useSelector(
    (state) => state.updateUsersStatusReducer
  );
  const { success, error, loading: udpateLoading } = getUpdateStatus;

  // ***************************Handle*********************************

  const handleLive = (id) => {};
  const handleDelisted = (id) => {};
  const handleBlockUser = async (id, checkedValueUpdated) => {
    dispatch(UpdateUserStatus({ userId: id, checkedValueUpdated }))
      .then(() => {
        dispatch(GetUsersAction(Page, Limit));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ******************** useEffect ***********************************
  useEffect(() => {
    dispatch(GetUsersAction(Page, Limit));
  }, []);

  // useEffect(()=> {
  //   if(udpateLoading == false)  {

  //   }
  // }, [udpateLoading])

  useEffect(() => {
    console.log(users, "users got");
    if (users?.length > 0) {
      let usersWithExtraFields = users.map((x) => {
        const checked = x.isBlocked == 1 ? false : true;
        return { ...x, checked };
      });
      setData(usersWithExtraFields);
      //   GetCoverImage();
    }
  }, [users]);

  const userTable = [
    { id: "name", label: "Name", minWidth: 10 },
    { id: "createdAt", label: "Created", minWidth: 10 },
    { id: "email", label: "Email", minWidth: 10 },
    { id: "agencyName", label: "Agency", minWidth: 10 },
    { id: "isBlocked", label: "Blocked", minWidth: 10 },
    { id: "actionBlockUser", label: "Action", minWidth: 140 },
  ];

  return (
    <Grid item container>
      <BreadCrum />

      <Grid
        item
        container
        component={Paper}
        elevation={0}
        className={classes.root}
      >
        <Typography className={classes.headerFont}>Users List</Typography>

        <Grid item container style={{ marginTop: "1rem" }}>
          {data?.length > 0 ? (
            <Table
              tableHead={userTable}
              tableData={data}
              Page={Page}
              setPage={setPage}
              Limit={Limit}
              setLimit={setLimit}
              totalPages={data.totalPages}
              handleBlockUser={handleBlockUser}
              //   handleLive={handleLive}
              //   handleDelisted={handleDelisted}
              //Edit modal
            />
          ) : (
            "No Users Found"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default User;
