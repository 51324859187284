import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#C12B2D";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#C12B2D",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "80%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ items, invoice }) => {
  const total = items
    .map((item) => item.qty * item.rate)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const totalItems = items.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);
  const btwCalculate = () => {
    return invoice.totalOrderCost * 0.21;
  };
  const totalCost = () => {
    return btwCalculate() + invoice.totalOrderCost;
  };
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Gross</Text>
        <Text style={styles.total}>
          € {Math.floor(invoice.totalOrderCost.toFixed(2))}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>BTW (21%)</Text>
        <Text style={styles.total}>
          € {Math.floor(btwCalculate().toFixed(2))}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>€ {Math.floor(totalCost().toFixed(2))}</Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
