import Cookies from "universal-cookie";
import instance from "../../Config/axios";
import { setAlert } from "./AlertAction";

const cookies = new Cookies();

const {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGOUT,

  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
  USER_STORED_PASSWORD_REQUEST,
  USER_STORED_PASSWORD_SUCCESS,
  USER_STORED_PASSWORD_FAIL,
} = require("../constants/authConstant");

// *********************************User Login*************************************
const userLogin = (formData) => async (dispatch) => {
  console.log(formData);

  try {
    dispatch({ type: USER_LOGIN_REQUEST, payload: formData });
    const { data } = await instance.post("/auth/admin-login", formData);
    console.log(data, "data in call");
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data && { token: data.token, userJson: data.data.user },
    });
    if (data) {
      cookies.set("token", data.token, { path: "/" });
      cookies.set("userData", data.data.user, { path: "/" });
    }
  } catch (error) {
    console.log(error.response, "err");
    setAlert("Oops!", `Something went wrong`, "error");
    dispatch({ type: USER_LOGIN_FAIL, payload: error.response?.data });
  }
};

const logout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  cookies.remove("token", { path: "/" });
  cookies.remove("userData", { path: "/" });
  window.location.href = "/login";
};

/********************** Register******************/

const register = (formData) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: formData });

  try {
    const { data } = await instance.post("/api/users/signup", formData);

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error?.response });
  }
};

/**********************Reset Password******************/

const resetPassword = (email) => async (dispatch) => {
  dispatch({ type: USER_RESET_PASSWORD_REQUEST, payload: email });

  try {
    const { data } = await instance.post("/auth/reset-password", email);

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({ type: USER_RESET_PASSWORD_FAIL, payload: error?.response });
  }
};

/**********************Password Stored ******************/
const PasswordStored = (password) => async (dispatch) => {
  dispatch({ type: USER_STORED_PASSWORD_REQUEST, payload: password });

  try {
    const { data } = await instance.post("/store-password", password);

    dispatch({
      type: USER_STORED_PASSWORD_SUCCESS,
      payload: data,
      success: true,
    });
  } catch (error) {
    dispatch({ type: USER_STORED_PASSWORD_FAIL, payload: error?.response });
  }
};

export { userLogin, logout, register, resetPassword, PasswordStored };
