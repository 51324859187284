import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../BreadCrum/BreadCrum";
import instance from "../../Config/axios";
import Table from "../Table/index";

import {
  GetProductAction,
  DeleteProduct,
} from "../../reduxState/aciton/productAction";

const useStyles = makeStyles((theme) => ({
  root: { padding: "1rem" },
  headerFont: {
    fontSize: "1.1rem",
    fontFamily: "sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

function Product() {
  const classes = useStyles();
  // ******************* useState **********************************
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(10);
  const [data, setData] = useState({});

  // ******************* Redux State********************************
  const dispatch = useDispatch();
  const getProduct = useSelector((state) => state.getProduct);
  const { products } = getProduct;
  const deleteProduct = useSelector((state) => state.deleteProduct);
  const { message } = deleteProduct;

  // ***************************Handle*********************************

  // const GetCoverImage = async () => {
  //   let coverPhoto = {};
  //   if (products.docs) {
  //     await products.docs.map((x, index) =>
  //       instance.get(`/api/products/photo/${x.coverPhoto}`).then((x) => {
  //         products.docs[index].coverPhoto = x.data.imageView;
  //         coverPhoto = { ...products };
  //         setData(coverPhoto);
  //       })
  //     );
  //   }
  // };

  const handleLive = (id) => {};
  const handleDelisted = (id) => {};
  const handleDelete = (id) => {
    dispatch(DeleteProduct(id));
  };
  // ******************** useEffect ***********************************
  useEffect(() => {
    dispatch(GetProductAction(Page, Limit));
  }, []);

  useEffect(() => {
    console.log(products, "products");
    if (products?.length > 0) {
      setData(products);
      //   GetCoverImage();
    }
  }, [products]);

  useEffect(() => {
    dispatch(GetProductAction(Page, Limit));
  }, [message]);

  const ProductTable = [
    { id: "image", label: "Image", minWidth: 10 },
    { id: "name", label: "Name", minWidth: 10 },
    { id: "volume", label: "Volume", minWidth: 10 },
    { id: "status", label: "Status", minWidth: 10 },
    { id: "categoryId", label: "categoryId", minWidth: 10 },
    { id: "createdAt", label: "created", minWidth: 10 },
    { id: "action", label: "Action", minWidth: 140 },
  ];

  return (
    <Grid item container>
      <BreadCrum />

      <Grid
        item
        container
        component={Paper}
        elevation={0}
        className={classes.root}
      >
        <Typography className={classes.headerFont}>Product List</Typography>

        <Grid item container style={{ marginTop: "1rem" }}>
          {data?.length > 0 ? (
            <Table
              tableHead={ProductTable}
              tableData={data}
              Page={Page}
              setPage={setPage}
              Limit={Limit}
              setLimit={setLimit}
              totalPages={data.length}
              handleDelete={handleDelete}
              //   handleLive={handleLive}
              //   handleDelisted={handleDelisted}
              //Edit modal
            />
          ) : (
            "No Product Found"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Product;
