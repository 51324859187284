import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PMCLogo from "../../../asset/image/sonicmovers-logo.webp";
import emailImg from "../../../asset/image/email.png";
import telephoneImg from "../../../asset/image/telephone.png";
import pinImg from "../../../asset/image/placeholder-2.png";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export default function Header() {
  const matchesSm = useMediaQuery("(min-width:600px)");
  return (
    <>
      <Grid
        container
        style={{
          maxWidth: "1200px",
          wordBreak: "break-all",
          justifyContent: "center",
          borderBottom: "1px solid rgb(227 200 200 / 50%)",
        }}
      >
        {/* {!matchesSm && (
          <Grid
            item
            xs={12}
            sm={9}
            style={{
              display: "flex",
              background: "#f15a24",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              padding: "10px",
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: "#fff",
                fontSize: "1em",
                display: "flex",
                gap: "2px",
              }}
              // component="span"
            >
              <LocationOnIcon fontSize="small" />
              info@professionalmovingcompany.nl
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: "#fff",
                fontSize: "1em",
                display: "flex",
                gap: "2px",
              }}
              // component="span"
            >
              <PhoneIcon fontSize="small" />
              +31 6 23847001
            </Typography>
          </Grid>
        )} */}
        <Grid
          item
          xs={12}
          sm={3}
          style={{
            textAlign: "center",
            justifyContent: "center",
            paddingTop: "1.2rem",
            paddingBottom: "1rem",
          }}
        >
          <img
            src={PMCLogo}
            style={{
              maxWidth: "150px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() =>
              (window.location.href = "https://sonicmovers.nl/")
            }
          />
        </Grid>
        {/* {matchesSm && (
          <Grid item xs={12} sm={9} style={{ display: "flex" }}>
            <Grid
              item
              sm={4}
              style={{
                display: "flex",
                alignItems: "flex-start",
                padding: "20px",
              }}
            >
              <img src={telephoneImg} style={{ maxWidth: "32px" }} />
              <div style={{ paddingLeft: "15px" }}>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#777777", fontSize: "1em" }}
                  // component="span"
                >
                  <strong>Any Question at</strong>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#777777", fontSize: "1em" }}
                  // component="span"
                >
                  +31 6 23847001
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              sm={4}
              style={{
                display: "flex",
                alignItems: "flex-start",
                padding: "20px",
              }}
            >
              <img src={pinImg} style={{ maxWidth: "32px" }} />
              <div style={{ paddingLeft: "15px" }}>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#777777", fontSize: "1em" }}
                  // component="span"
                >
                  <strong>We are active in</strong>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#777777", fontSize: "1em" }}
                  // component="span"
                >
                  all the Netherlands
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              sm={4}
              style={{
                display: "flex",
                alignItems: "flex-start",
                padding: "20px",
              }}
            >
              <img src={emailImg} style={{ maxWidth: "32px" }} />
              <div style={{ paddingLeft: "15px" }}>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#777777", fontSize: "1em" }}
                  // component="span"
                >
                  <strong>Send your email at</strong>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#777777", fontSize: "1em" }}
                  // component="span"
                >
                  info@professionalmovingcompany.nl
                </Typography>
              </div>
            </Grid>
          </Grid>
        )} */}
      </Grid>
    </>
  );
}
