import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#C12B2D",
  },
});

const InvoiceItemsTable = ({ invoice }) => {
  const tableRowsCount = invoice.items.length + 2;

  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={invoice.items} />
      <InvoiceTableBlankSpace
        rowsCount={tableRowsCount - invoice.items.length}
      />
      <InvoiceTableFooter items={invoice.items} invoice={invoice} />
    </View>
  );
};

export default InvoiceItemsTable;
