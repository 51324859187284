import React, { useState } from "react";
// @material-ui/core components
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import DoneIcon from "@material-ui/icons/Done";
// core components
import {
  Grid,
  IconButton,
  Button,
  TableContainer,
  TablePagination,
  Tooltip,
  makeStyles,
  Chip,
  Typography,
  Paper,
  TableSortLabel,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import CreateIcon from "@material-ui/icons/Create";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "14px",
    fontWeight: "550",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  tabelCellFont: {
    fontSize: "12px",
  },
  PName: {
    fontSize: "12px",
    fontWeight: "550",
    textTransform: "capitalize",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function CustomTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    Limit,
    Page,
    // ActionFunction,
    handleLive,
    handleDelisted,
    handleBlockUser,
    // tableHeaderColor,
    // DeleteDailog,
    // Delete,
    // setHandCloseDailog,
    orderStatus,
    changeOrderStatusClick,
    orderItemStatus,
    changeOrderItemStatusClick,
  } = props;

  //   *****************************************************************************************
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("invoiceNo");
  //**************************************Handle Change Page*********************************** */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const toggleChecked = () => {
    setTimeout(() => {
      setChecked((prev) => !prev);
    }, 2000);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log(tableData);

  // *******************************************************************************************
  function Row(props) {
    const { row, tableHead, orderStatus } = props;
    const [open, setOpen] = React.useState(false);
    const [orderStatusSelected, setOrderStatusSelected] = useState("");
    const [orderItemStatusSelected, setOrderItemStatusSelected] = useState("");

    const handleOrderStatusChange = (e) => {
      console.log(e, "id to change status");
      setOrderStatusSelected(e.target.value);
    };
    const handleOrderItemStatusChange = (e) => {
      console.log(e, "id to change status");
      setOrderItemStatusSelected(e.target.value);
    };
    console.log(row, "row");
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {tableHead &&
            tableHead.map((column, key) => {
              const value = row[column.id];

              switch (column.id) {
                case "action":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          aria-label="Delete"
                          color="primary"
                          // onClick={() => {
                          // 	DeleteDailog(true);
                          // 	Delete && Delete(row.id);
                          // }}
                        >
                          <DeleteSweepOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          // onClick={() => {
                          // 	setHandCloseDailog(true);
                          // 	ActionFunction &&
                          // 	dispatch(ActionFunction(row.id));
                          // 	props.setID && props.setID(row.id);
                          // 	console.log(row.id, 'row id');
                          // }}
                        >
                          <CreateIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  );
                case "actionOrderStatus":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{ minWidth: 250, borderBottom: "none" }}
                    >
                      <FormControl style={{ minWidth: 150 }}>
                        <Select
                          value={orderStatusSelected}
                          onChange={(e) => handleOrderStatusChange(e)}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="" disabled>
                            Status
                          </MenuItem>
                          {orderStatus.length &&
                            orderStatus.map((x) => {
                              return <MenuItem value={x}>{x}</MenuItem>;
                            })}
                          {/* <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                      </FormControl>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          changeOrderStatusClick(row._id, orderStatusSelected)
                        }
                      >
                        Change
                      </Button>
                    </TableCell>
                  );
                case "actionBlockUser":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Tooltip title="Block/Unblock" arrow>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={row.checked}
                              onChange={(e) =>
                                handleBlockUser(row._id, !row.checked)
                              }
                            />
                          }
                        />
                      </Tooltip>
                    </TableCell>
                  );

                case "actionBookUser":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      <Tooltip title="Book/Unbook" arrow>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={row.isBooked}
                              onChange={(e) =>
                                changeOrderItemStatusClick(
                                  row._id,
                                  "isBooked",
                                  !row.isBooked
                                )
                              }
                            />
                          }
                        />
                      </Tooltip>
                    </TableCell>
                  );
                case "actionConfirmOrder":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      <Tooltip title="Confirm" arrow>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={row.isOrderConfirmed}
                              onChange={(e) =>
                                changeOrderItemStatusClick(
                                  row._id,
                                  "isOrderConfirmed",
                                  !row.isOrderConfirmed
                                )
                              }
                            />
                          }
                        />
                      </Tooltip>
                    </TableCell>
                  );

                case "coverPhoto":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{ borderBottom: "none" }}
                    >
                      {
                        <img
                          src={value?.imgurl}
                          width="70px"
                          height="50px"
                          style={{ objectFit: "cover" }}
                          alt={value?.title}
                        />
                      }
                    </TableCell>
                  );

                case "createdAt":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      {value ? new Date(value).toLocaleDateString() : "-"}
                    </TableCell>
                  );

                case "dateOfMove":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      {value ? new Date(value).toLocaleDateString() : "-"}
                    </TableCell>
                  );

                case "timeOfMove":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      {value == 1
                        ? "Morning (7 - 9)"
                        : value == 2
                        ? "Afternoon (12 - 3)"
                        : "Evening (5 - 7)"}
                    </TableCell>
                  );

                case "variation":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Typography className={classes.tabelCellFont}>
                        Name: {value[0]?.name}
                        <br />
                        Price: {value[0]?.variance?.price}
                        <br />
                        Stock: {value[0]?.variance?.stock}
                      </Typography>
                    </TableCell>
                  );

                case "status":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      {value === "pending" || value === "cancelled" ? (
                        <Chip
                          // onDelete={() => handleLive()}
                          color="secondary"
                          label={
                            <Typography
                              style={{
                                fontSize: "11px",
                                color: "#fff",
                              }}
                            >
                              {value}
                            </Typography>
                          }
                          size="small"
                        />
                      ) : (
                        <Chip
                          color="secondary"
                          label={value}
                          size="small"
                          // deleteIcon={<DoneIcon />}
                          // onDelete={() => handleDelisted()}
                        />
                      )}
                    </TableCell>
                  );

                case "isBlocked":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Typography className={classes.PName}>
                        {value == 1 ? "Yes" : "No"}
                      </Typography>
                    </TableCell>
                  );
                case "name":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Typography className={classes.PName}>
                        {value ? value : "-"}
                      </Typography>
                    </TableCell>
                  );
                case "is_blocked":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Typography className={classes.PName}>
                        {value == 0 ? "No" : "Yes"}
                      </Typography>
                    </TableCell>
                  );
                case "isDrafted":
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Typography className={classes.PName}>
                        {value.toString()}
                      </Typography>
                    </TableCell>
                  );

                default:
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        borderBottom: "none",
                      }}
                    >
                      <Typography className={classes.tabelCellFont}>
                        {value ? value : "-"}
                      </Typography>
                    </TableCell>
                  );
              }
            })}
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={3}>
                <Grid container>
                  <Grid xs={12} sm={6}>
                    <Typography className={classes.tabelCellFont}>
                      Mobile: {row.mobile}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      Description: {row.description}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      Workers Required: {row.workersRequired}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      Cargo: {row.cargoLifter}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      Created At: {new Date(row.createdAt).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Typography className={classes.tabelCellFont}>
                      Total Distance: {row.totalDistance}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      Total Volume: {row.totalVolume}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      Total Amount: {row.totalOrderAmount}
                    </Typography>
                    <Typography className={classes.tabelCellFont}>
                      <a
                        href={`${process.env.REACT_APP_HOST}${row.pdf}`}
                        target="_blank"
                      >
                        Download Invoice
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box margin={3}>
                <Typography variant="h6" gutterBottom component="div">
                  Order Items
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell className={classes.header}>Name</TableCell>
                      <TableCell className={classes.header}>Quantity</TableCell>
                      <TableCell className={classes.header} align="right">
                        Volume (m3)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.cargo.map((orderItem) => (
                      <TableRow key={orderItem._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ textTransform: "capitalize" }}
                        >
                          {orderItem.name}
                        </TableCell>
                        <TableCell>{orderItem.quantity}</TableCell>
                        <TableCell align="right">{orderItem.volume}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function SubRow(props) {
    const { orderItem, changeOrderItemStatusClick, orderItemStatus } = props;
    const [orderItemStatusSelected, setOrderItemStatusSelected] = useState("");

    const handleOrderItemStatusChange = (e) => {
      console.log(e, "id to change status");
      setOrderItemStatusSelected(e.target.value);
    };

    return (
      <>
        <FormControl style={{ minWidth: 150 }}>
          <Select
            value={orderItemStatusSelected}
            onChange={(e) => handleOrderItemStatusChange(e)}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              Status
            </MenuItem>
            {orderItemStatus.length &&
              orderItemStatus.map((x) => {
                return <MenuItem value={x}>{x}</MenuItem>;
              })}
            {/* <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ marginLeft: "10px" }}
          onClick={() =>
            changeOrderItemStatusClick(orderItem._id, orderItemStatusSelected)
          }
        >
          Change
        </Button>
      </>
    );
  }
  //   ****************************************UI********************************************
  return (
    <Grid item container component={Paper} variant="outlined">
      <TableContainer className={classes.container}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          {tableHead !== undefined ? (
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headCells={tableHead}
            />
          ) : // <TableHead>
          //   <TableRow className={classes.tableHeadRow}>
          //     {tableHead.map((prop, key) => {
          //       return (
          //         <TableCell align="center" key={key}>
          //           <Typography className={classes.header}>
          //             {prop.label}
          //           </Typography>
          //         </TableCell>
          //       );
          //     })}
          //     <TableCell align="left">
          //       <Typography className={classes.header}>Details</Typography>
          //     </TableCell>
          //   </TableRow>
          // </TableHead>
          null}
          <TableBody>
            {tableData &&
              stableSort(tableData, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      key={row._id}
                      row={row}
                      tableHead={tableHead}
                      orderStatus={orderStatus}
                      changeOrderStatusClick={changeOrderStatusClick}
                      orderItemStatus={orderItemStatus}
                      changeOrderItemStatusClick={changeOrderItemStatusClick}
                    />
                    // <TableRow key={index} hover role="action" tabIndex={-1}>
                    //   <TableCell>
                    //     <IconButton
                    //       aria-label="expand row"
                    //       size="small"
                    //       onClick={() => setOpen(!open)}
                    //     >
                    //       {open ? (
                    //         <KeyboardArrowUpIcon />
                    //       ) : (
                    //         <KeyboardArrowDownIcon />
                    //       )}
                    //     </IconButton>
                    //   </TableCell>

                    // </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ borderBottom: "#000000" }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

export default React.memo(CustomTable);
