import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import instance from '../../../../Config/axios';
import { pdf, PDFViewer, Document, Page, Text, renderToStream } from '@react-pdf/renderer';
import Invoice from '../../../Reports/Invoice';
import Information from './Information';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOrder } from '../../../../reduxState/aciton/orderAction';
import InformationDialog from './InformationDialog';
import CustomButton from '../../../ui/CustomButton';

export default function Review({ handlers }) {
	let dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [invoiceState, setInvoiceState] = React.useState();

	const {
		orderCalculations,
		selectedPickupLocation,
		selectedDeliveryLocation,
		addressDistance,
		totalDistance,
		totalStairs,
		selectedItemsVolume,
		selectedItems,
		formData,
		selectedDate,
		selectedTimeslot,
		cargoHours,
		selectedPickupAddress,
		selectedDeliveryAddress,
	} = handlers;
	const { totalOrderCost, totalKMCost, totalWorkersCost, totalStairsCost, totalTruckCost, totalCargoCost, totalServiceTime } = orderCalculations;
	const { name, email, mobile, cargo, numberOfWorkers, numberOfStairsFirstAddress, numberOfStairsSecondAddress } = formData;

	console.log(selectedTimeslot, 'time slot selected');
	let invoiceGenerator = Math.random().toString(36).substr(2, 9);
	let invoiceNo = `${new Date().getFullYear()}-${invoiceGenerator.toUpperCase()}`;
	const invoice = {
		// id: "5df3180a09ea16dc4b95f910",
		invoice_no: invoiceNo,
		balance: '$2,283.74',
		firstName: name,
		lastName: '',
		email: email,
		phone: mobile,
		// address: "922 Campus Road, Drytown, Wisconsin, 1986",
		trans_date: new Date().toLocaleDateString(),
		due_date: new Date().toLocaleDateString(),
		totalDistance: addressDistance,
		totalOrderCost,
		selectedItemsVolume: selectedItemsVolume.toFixed(1),
		selectedDate,
		selectedTimeslot,
		selectedPickupAddress,
		selectedDeliveryAddress,
		selectedItems,
		freeMaterial: [
			{
				_id: 1,
				name: 'Moving blankets',
			},
			{
				_id: 2,
				name: 'Sofa covers',
			},
			{
				_id: 3,
				name: 'Armchair covers',
			},
			{
				_id: 4,
				name: 'Rolling materials (furniture rollers, hand truck, etc.)',
			},
			{
				_id: 5,
				name: 'Threshold plate, ramp, wedges, etc.',
			},
			{
				_id: 6,
				name: 'Piano protective cover (only for piano removals).',
			},
		],
		items: [
			// ...selectedItems,
			{
				sno: 1,
				name: 'Moving Service',
				description: `${Math.ceil(totalServiceTime / 60)} hours moving service with ${numberOfWorkers} movers`,
				rate: totalWorkersCost?.toFixed(2),
			},
			{
				sno: 2,
				name: 'Moving Lift',
				description: `${cargoHours} hours`,
				rate: totalCargoCost?.toFixed(2),
			},
			{
				sno: 3,
				name: 'Floors',
				description: `${totalStairs}`,
				rate: totalStairsCost?.toFixed(2),
			},
			{
				sno: 4,
				name: 'Moving Truck',
				description: `Truck ${selectedItemsVolume > 24 ? '48' : '24'} cubic meter`,
				rate: totalTruckCost?.toFixed(2),
			},
			{
				sno: 5,
				name: 'KM Costs',
				description: 'Travel Costs',
				rate: totalKMCost?.toFixed(2),
			},
		],
	};
	if (!(cargoHours > 0)) {
		invoice.items = invoice.items.filter((ele) => ele.sno !== 2);
	}

	const submitOrder = (isDrafted = false) => {
		let orderData = {
			invoiceNo: invoiceNo,
			name,
			email,
			mobile,
			workersRequired: numberOfWorkers,
			cargoLifter: cargo === 'yes' ? true : false,
			pickupLocation: {
				coordinates: [selectedPickupLocation.lng, selectedPickupLocation.lat],
				address: 'abc',
			},
			deliveryLocation: {
				coordinates: [selectedDeliveryLocation.lng, selectedDeliveryLocation.lat],
				address: 'abc',
			},
			dateOfMove: selectedDate,
			timeOfMove: selectedTimeslot,
			totalDistance,
			totalVolume: selectedItemsVolume,
			totalOrderAmount: totalOrderCost,
			isDrafted,
			cargo: selectedItems,
		};
		dispatch(CreateOrder(orderData, sendStream));
	};
	const sendStream = async (orderId) => {
		// const stream = await renderToStream(<Invoice invoice={invoice} />);
		const blob = await pdf(<Invoice invoice={invoice} />).toBlob();
		console.log(blob, 'blob');
		var fileOfBlob = new File([blob], 'invoice.pdf');
		let productFormData = new FormData();
		productFormData.append('file', fileOfBlob);

		const res = await instance.post('/orders/upload-pdf-stream/' + orderId, productFormData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return res;
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		console.log(invoice, 'invoice is here');
		if (invoice) {
			setInvoiceState(invoice);
		}
		submitOrder(true);
	}, []);
	return (
		<Grid
			container
			direction='row'
			spacing={3}>
			<Grid
				item
				container
				style={{ justifyContent: 'center' }}>
				<Information handlers={handlers} />
				<InformationDialog
					handlers={handlers}
					handleClickOpen={handleClickOpen}
					handleClose={handleClose}
					submitOrder={submitOrder}
					open={open}
				/>
				<Grid
					item
					container
					style={{ justifyContent: 'center', marginBottom: '40px', marginTop: '40px' }}>
					<CustomButton
						id='btn-confirm-booking'
						variant='outlined'
						color='primary'
						style={{ background: '#d0372b', color: 'white', border: 'none' }}
						onClick={handleClickOpen}>
						Confirm Booking
					</CustomButton>
				</Grid>
				{invoiceState && (
					<PDFViewer
						width='1000'
						height='600'
						className='app'>
						<Invoice invoice={invoiceState} />
					</PDFViewer>
				)}
				{/* <Grid
          item
          container
          style={{ justifyContent: "flex-end", marginTop: "40px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => submitOrder()}
          >
            Submit Order
          </Button>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Proceed
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => submitOrder(true)}
            style={{ marginLeft: "10px", background: "#808080" }}
          >
            Email My Order
          </Button>
        </Grid> */}
			</Grid>
		</Grid>
	);
}
