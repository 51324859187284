import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const prettyMetric = require("pretty-metric");

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

const OrderDetails = ({ invoice }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Order Details:</Text>
    {/* <Text>Total Order Amount: {invoice.totalOrderCost.toFixed(2)}</Text> */}
    <Text>Pickup Address: {invoice.selectedPickupAddress}</Text>
    <Text>Delivery Address: {invoice.selectedDeliveryAddress}</Text>
    <Text>Date of Move: {new Date(invoice.selectedDate).toDateString()}</Text>
    <Text>
      Time of Move:{" "}
      {invoice.selectedTimeslot == 1
        ? "Morning (7 - 9)"
        : invoice.selectedTimeslot == 2
        ? "Afternoon (12 - 3)"
        : "Evening (5 - 7)"}
    </Text>
    <Text>
      {/* Total Distance: {(invoice.totalDistance / 1000).toFixed(2)} */}
      Total Distance: {prettyMetric(invoice.totalDistance).humanize()}
    </Text>
    <Text>Total Volume: {invoice.selectedItemsVolume} m3</Text>
  </View>
);

export default OrderDetails;
