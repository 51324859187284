import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
  USER_STORED_PASSWORD_REQUEST,
  USER_STORED_PASSWORD_SUCCESS,
  USER_STORED_PASSWORD_FAIL,
} from "../constants/authConstant";

// ********************************** user Login ******************************************
function userLoginReducer(state = {}, action) {
  console.log(action.payload, "action payload");
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload.token,
        userInfoData: action.payload.userJson,
      };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    // case USER_LOGOUT:
    //   return {};
    default:
      return state;
  }
}

// ************************************ userReducer *************************************
function userRegisterReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// ************************************ userReducer *************************************
function userResetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, message: action.payload, success: true };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
// ************************************ Password Restore Reducer *************************************
function userPasswordRestoreReducer(state = {}, action) {
  switch (action.type) {
    case USER_STORED_PASSWORD_REQUEST:
      return { loading: true };
    case USER_STORED_PASSWORD_SUCCESS:
      return { loading: false, message: action.payload, success: true };
    case USER_STORED_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  userLoginReducer,
  userRegisterReducer,
  userResetPasswordReducer,
  userPasswordRestoreReducer,
};
