import {
  ADD_CATEGORIES_FAIL,
  ADD_CATEGORIES_REQUEST,
  ADD_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
} from "../constants/categoryConstant";

// ************************************ Add CATEGORIES *************************************
function AddCategoryReducer(state = { categories: {} }, action) {
  switch (action.type) {
    case ADD_CATEGORIES_REQUEST:
      return { loading: true };
    case ADD_CATEGORIES_SUCCESS:
      return { loading: false, categories: action.payload, success: true };
    case ADD_CATEGORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
// ************************************ Get CATEGORIES *************************************
function GetCategoryReducer(state = { categories: {} }, action) {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return { loading: true, categories: {} };
    case GET_CATEGORIES_SUCCESS:
      return { loading: false, categories: action.payload, success: true };
    case GET_CATEGORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { AddCategoryReducer, GetCategoryReducer };
