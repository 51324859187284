import React from 'react';
import './Styles/CustomButton.css';

const CustomButton = ({ onClick, children, variant = 'contained', color = 'primary', ...props }) => {
	return (
		<button
			className={`custom-button ${variant} ${color}`}
			onClick={onClick}
			{...props}>
			{children}
		</button>
	);
};

export default CustomButton;
