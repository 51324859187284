import React, { useEffect, useState } from "react";
import {
  Button,
  Fade,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { register } from "../../reduxState/aciton/authAction";

import MuiAlert from "@material-ui/lab/Alert";

const useStyle = makeStyles((theme) => ({
  background: {
    color: "#e6e6e6",
    margin: "0 auto",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  Paper: {
    width: "580px",

    color: "#f6f6f6",
    borderRadius: "17px",
    padding: "2em",
  },
  Heading: {
    marginTop: "1em",
    fontSize: "1.3em",
    fontFamily: "Poppins, sans-serif",
    color: `${theme.palette.common.pink}`,
  },
  Link: { textDecoration: "none" },
  text: {
    fontFamily: "Poppins, sans-serif",
    fontSize: ".8rem",
    lineHeight: "1rem",
    fontWeight: "bold",
    color: "#666666",
    marginBottom: ".5em",
  },
  Button: {
    borderRadius: "20px",
    padding: "13px",
    backgroundColor: `${theme.palette.common.pink}`,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Register() {
  const users = ["doctor", "patient"];
  const options = ["92"];

  const classes = useStyle();
  // let navigate = useNavigate();

  // ***************************************state *********************************************
  const [formData, setformData] = useState({
    username: "",
    // firstname: '',
    // lastname: '',
    countryCode: "",
    phone: "",
    email: "",
    password: "",
    userType: "",
  });
  const [validationHelper, setValidationHelper] = useState({
    userNameHelper: "",
    // firstNameHelper: '',
    // lastNameHelper: '',
    phoneHelper: "",
    emailHelper: "",
    passwordHelper: "",
    userTypeHelper: "",
  });

  const { username, phone, email, password } = formData;
  const { userNameHelper, phoneHelper, emailHelper, passwordHelper } =
    validationHelper;

  // *********************************OnChange handle**********************************************
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
    let valid;

    switch (e.target.name) {
      case "email":
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          e.target.value
        );
        if (!valid) {
          setValidationHelper({
            ...validationHelper,
            emailHelper: "Invalid Email",
          });
        } else {
          setValidationHelper({ ...validationHelper, emailHelper: "" });
        }
        break;

      // case 'phone':
      // 	valid = /^0?3(?:[0-46]\d|55)\d{7}$/.test(e.target.value);
      // 	if (!valid) {
      // 		setValidationHelper({
      // 			...validationHelper,
      // 			phoneHelper: 'Invalid Number',
      // 		});
      // 	} else {
      // 		setValidationHelper({ ...validationHelper, emailHelper: '' });
      // 	}
      // 	break;
      default:
        break;
    }
  };

  console.log(formData);
  // ***************************Redux State***************************
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { success, error } = userRegister;

  // ***************************Validation**************************

  const Validation = () => {
    let isError = true;

    if (username.length === 0) {
      isError = false;

      validationHelper.userNameHelper = "UserName is Required";
    }
    // if (firstname.length === 0) {
    // 	isError = false;

    // 	validationHelper.firstNameHelper = 'First Name is Required';
    // }
    // if (lastname.length === 0) {
    // 	isError = false;

    // 	validationHelper.lastNameHelper = 'Last Name  is Required';
    // }
    if (password.length < 6) {
      isError = false;

      validationHelper.passwordHelper = "Password must be greater than 6";
    }
    if (email.length === 0) {
      isError = false;

      validationHelper.emailHelper = "Email is Required";
    }

    if (phone.length === 0) {
      isError = false;
      validationHelper.phoneHelper = "Phone is Required";
    }

    setValidationHelper({
      ...validationHelper,
    });

    return isError;
  };

  // *******************************onSubmit ******************************************************
  const onSubmit = (e) => {
    let err = Validation();

    if (err) {
      e.preventDefault();
      dispatch(register(formData));
    }
  };

  const fields = [
    {
      key: 0,
      fiedlName: "User Name",
      name: "username",
      value: username,
      error: userNameHelper,
    },
    // {
    // 	key: 1,
    // 	fiedlName: 'First Name',
    // 	name: 'firstname',
    // 	value: firstname,
    // 	error: firstNameHelper,
    // },
    // {
    // 	key: 2,
    // 	fiedlName: 'Last Name',
    // 	name: 'lastname',
    // 	value: lastname,
    // 	error: lastNameHelper,
    // },
    {
      key: 3,
      fiedlName: "Email",
      name: "email",
      value: email,
      error: emailHelper,
    },
    {
      key: 4,
      fiedlName: "Password",
      name: "password",
      value: password,
      error: passwordHelper,
      type: "password",
    },
  ];

  // Snackbar
  const [openSanckbar, setOpenSnackbar] = useState({
    open: false,
    severity: "",
  });

  // select country code
  const [inputCode, setInputCode] = React.useState(options[0]);
  // select User Type
  const [inputUserType, setInputUserType] = React.useState(users[1]);

  // *************************************useEffect***********************************************
  useEffect(() => {
    if (error) {
      setOpenSnackbar({
        open: true,
        severity: "error",
        message: error?.data?.Error,
      });
    }
    if (success) {
      setOpenSnackbar({
        open: true,
        severity: "success",
        message: "Sucessfully Resgister!",
      });
    }
  }, [success, error]);

  return (
    <Grid
      item
      container
      className={classes.background}
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        component={Paper}
        elevation={3}
        className={classes.Paper}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justifyContent="center"
          className={classes.Heading}
        >
          <Typography variant="h4">Register To Virtual Hospital</Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: "1.3em" }}
        >
          {fields?.map((item) => (
            <Grid
              item
              container
              justify="center"
              style={{ marginTop: "1.5em" }}
              key={item.key}
            >
              <Grid item container>
                <Typography variant="h4" className={classes.text}>
                  {item.fiedlName}
                </Typography>
              </Grid>
              <TextField
                type={item.password && item.password}
                id={item.name}
                variant="outlined"
                placeholder={item.fiedlName}
                fullWidth
                inputProps={{
                  style: {
                    padding: 10,
                    fontSize: "1rem",
                  },
                }}
                name={item.name}
                value={item.value}
                error={item?.error?.length !== 0}
                helperText={item.error}
                onChange={onChange}
              />
            </Grid>
          ))}

          <Grid item container style={{ marginTop: "1.5rem" }}>
            <Grid item md={3} sm={3}>
              <Grid item container>
                <Typography variant="h4" className={classes.text}>
                  Country Code
                </Typography>
              </Grid>
              <Autocomplete
                size="small"
                value={inputCode}
                id="countrycode"
                options={options}
                style={{ width: 115 }}
                inputValue={inputCode}
                onInputChange={(event, newInputValue) => {
                  setInputCode(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setformData({ countryCode: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Grid>
            <Grid item md={9} sm={9}>
              <Grid item container style={{ marginBottom: "0.1rem" }}>
                <Typography variant="h4" className={classes.text}>
                  Phone No
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="phoneNumber"
                  variant="outlined"
                  placeholder="300 1234567"
                  fullWidth
                  inputProps={{
                    style: {
                      padding: 12.4,
                      fontSize: "1rem",
                    },
                  }}
                  name="phone"
                  value={phone}
                  error={phoneHelper?.length !== 0}
                  helperText={phoneHelper}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container style={{ marginTop: "1.3rem" }}>
            <Grid item container>
              <Typography variant="h4" className={classes.text}>
                User Type
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                size="small"
                value={inputUserType}
                id="userType"
                options={users}
                inputValue={inputUserType}
                onInputChange={(event, newInputValue) => {
                  setInputUserType(newInputValue);
                }}
                fullWidth
                style={{ width: 515 }}
                onChange={(event, newValue) => {
                  setformData({ userType: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: "2em" }}
          >
            <Button
              onClick={onSubmit}
              fullWidth
              variant="contained"
              className={classes.Button}
            >
              Register
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          style={{ marginTop: "1em" }}
        >
          <Link to="/Login" className={classes.Link}>
            <Typography variant="h5">
              Already have an account ?{" "}
              <span style={{ color: "#ff9326", fontWeight: 400 }}>
                {" "}
                Register
              </span>
            </Typography>
          </Link>
        </Grid>
      </Grid>

      {/* ********************************Snackbar ********************************* */}
      <Snackbar
        open={openSanckbar.open}
        autoHideDuration={6000}
        TransitionComponent={Fade}
        onClose={() => {
          setOpenSnackbar({ open: false });
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar({ open: false });
          }}
          severity={openSanckbar.severity}
        >
          {openSanckbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
export default Register;
