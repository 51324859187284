import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../component/Login/index";
import Register from "../component/Register/index";
import Layout from "../component/ui/Layout";
import ForgetPassword from "../component/ResetPassword/Email";
import ResetPassword from "../component/ResetPassword/RestoredPassword";

import { PrivateRoute } from "./index";
import DashBoardStats from "../component/DashboardStats/index";
import Category from "../component/Category/index";
import Product from "../component/Product/index";
import Setting from "../component/Settings";
import Order from "../component/Order/index";
import CreateProduct from "../component/Product/createProduct";
import CreateCategory from "../component/Category/createCategory";
import User from "../component/User/index";
import CreateUser from "../component/User/createUser";
import NotFound from "../component/NotFound/NotFound";
import NotAuthorized from "../component/NotAuthorized/NotAuthorized";
import Check from "../component/CalculateVolumeStepper/GoogleAutocomplete";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../component/ui/themeAdmin";

const CustomRouter = () => {
  // let navigate = useNavigate();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/Login" />} />
          <Route path="/check" element={<Check />} />
          <Route path="Register" element={<Register />} />
          <Route path="ForgetPasword" element={<ForgetPassword />} />
          <Route path="ResetPassword" element={<ResetPassword />} />
          <Route path="Dashboard/" element={<Layout />} /> */}

          <Route path="login" element={<Login />} />
          <PrivateRoute path="dashboard/*" element={Layout}>
            {/* <PrivateRoute path="main" element={DashBoardStats} /> */}
            <PrivateRoute path="main" element={Product} />
            <PrivateRoute path="categories" isAdmin={true} element={Category} />
            <PrivateRoute
              path="create-category"
              isAdmin={true}
              element={CreateCategory}
            />
            <PrivateRoute path="products" isAdmin={true} element={Product} />
            <PrivateRoute path="settings" isAdmin={true} element={Setting} />
            <PrivateRoute path="users" isAdmin={true} element={User} />
            <PrivateRoute path="orders" isAdmin={true} element={Order} />
            <PrivateRoute
              path="create-user"
              isAdmin={true}
              element={CreateUser}
            />
            <PrivateRoute
              path="create-product"
              isAdmin={true}
              element={CreateProduct}
            />

            <PrivateRoute path="*" element={NotFound} />
            <PrivateRoute path="/not-authorized" element={NotAuthorized} />
          </PrivateRoute>

          {/* <AuthRoute path='login' element={<Login />} /> */}
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default CustomRouter;
