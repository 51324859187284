// ***********************Add ORDERS********************
export const ADD_ORDERS_SUCCESS = "ADD_ORDERS_SUCCESS";
export const ADD_ORDERS_REQUEST = "ADD_ORDERS_REQUEST";
export const ADD_ORDERS_FAIL = "ADD_ORDERS_FAIL";

// ***********************Add ORDERS********************
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

// ***********************UPDATE ORDERS STATUS********************
export const UPDATE_ORDERS_STATUS_SUCCESS = "UPDATE_ORDERS_STATUS_SUCCESS";
export const UPDATE_ORDERS_STATUS_REQUEST = "UPDATE_ORDERS_STATUS_REQUEST";
export const UPDATE_ORDERS_STATUS_FAIL = "UPDATE_ORDERS_STATUS_FAIL";

// ***********************UPDATE ORDERS ITEM STATUS********************
export const UPDATE_ORDERS_ITEM_STATUS_SUCCESS =
  "UPDATE_ORDERS_ITEM_STATUS_SUCCESS";
export const UPDATE_ORDERS_ITEM_STATUS_REQUEST =
  "UPDATE_ORDERS_ITEM_STATUS_REQUEST";
export const UPDATE_ORDERS_ITEM_STATUS_FAIL = "UPDATE_ORDERS_ITEM_STATUS_FAIL";
