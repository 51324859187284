import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  AppBar,
  Button,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import "../style/style.css";
import { FormControl, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: 12,
  },

  divider: {
    // Theme Color, or use css color in quote
    background: "#0000",
  },
  padding: {
    padding: ".5rem",
  },

  selectWidth: {
    width: "100%",
    marginTop: "7px",
    marginLeft: "10px",
    marginRight: "1px",
  },

  dropZone: {
    height: "10px",
    fullWidth: "true",
  },
  previewContainer: {
    container: "true",
    width: "100%",
    height: "100%",
  },
  preview: {
    //width: '100%',
    //height: '100%',
    //item: 'true',
    xs: "12",
  },
  previewImg: {
    //height: '100%',
    //width: '100%',
  },
}));

function Card({
  formValidation,
  formData,
  setformData,
  setCoverImage,
  featuresArr,
  setFeaturesArr,
}) {
  const classes = useStyles();

  // ********************** state***************************
  const { name, detail } = formData;

  const { nameHelper, detailHelper } = formValidation;

  const addFeaturesClick = () => {
    setFeaturesArr([
      ...featuresArr,
      {
        name: "",
        detail: "",
        price: "",
      },
    ]);
  };

  const removeFeaturesClick = (i) => {
    let statesClone = [...featuresArr];
    statesClone.splice(i, 1);
    setFeaturesArr(statesClone);
  };

  // Note: Function to handle variation form fields...!
  const handleChange = (event, index) => {
    const { name, value } = event.target;
    // console.log(name, value);
    let variationStatesClone = [...featuresArr];
    variationStatesClone[index] = {
      ...variationStatesClone[index],
      [name]: value,
    };
    setFeaturesArr(variationStatesClone);
  };

  console.log(featuresArr, "featuresArr");

  const createUIFeatureFields = () => {
    return featuresArr?.map((el, index) => {
      return (
        <Grid
          item
          container
          key={index}
          direction="row"
          style={{
            paddingTop: "1rem",
            width: "90%",
            justifyContent: "center",
          }}
        >
          {/* Field for variation name */}
          <TextField
            // id="standard-helperText"
            size="small"
            variant="outlined"
            label="Name"
            placeholder="Name"
            name="name"
            value={el.name || ""}
            style={{ marginRight: "10px", fontSize: "10" }}
            InputProps={{ style: { fontSize: "medium" } }}
            onChange={(event) => {
              handleChange(event, index);
            }}
          />
          {/* Field for variation price */}
          <TextField
            // id="standard-helperText"
            type="number"
            label="Price *"
            variant="outlined"
            placeholder="50"
            name="price"
            value={el.price || ""}
            onChange={(event) => {
              handleChange(event, index);
            }}
            size="small"
            style={{ marginRight: "10px" }}
            InputProps={{ style: { fontSize: "medium" } }}
          />
          {/* Field for variation type */}
          <TextField
            // id="standard-helperText"\
            variant="outlined"
            label="Details"
            placeholder="Details"
            name="detail"
            size="small"
            value={el?.detail || ""}
            style={{ marginRight: "10px" }}
            InputProps={{ style: { fontSize: "medium" } }}
            onChange={(event) => {
              handleChange(event, index);
            }}
          />

          {/* Delete icon */}
          <DeleteIcon
            onClick={() => {
              removeFeaturesClick(index);
            }}
            style={{ marginTop: "10px" }}
          />
        </Grid>
        // <div
        //   key={index}
        //   style={{
        //     padding: "20px",
        //     display: "flex",
        //     justifyContent: "space-between",
        //   }}
        // >

        // </div>
      );
    });
  };

  // ************************** handle Change **************************
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Grid item container component={Paper} elevation={1}>
      <Grid item container alignItems="center" style={{ padding: ".7rem" }}>
        <Typography variant="h2">Add New Category</Typography>
      </Grid>
      <Divider style={{ width: "100%" }} />

      {/* Row 1 */}
      <Grid item container direction="row" style={{ paddingTop: "1rem" }}>
        <Grid item container sm className={classes.padding}>
          <TextField
            id="name"
            name="name"
            value={name}
            InputProps={{ style: { fontSize: 12 } }}
            fullWidth
            size="small"
            label="Name"
            variant="outlined"
            onChange={onChange}
            error={nameHelper?.length >= 1}
            helperText={nameHelper}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Card;
