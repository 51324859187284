// ***********************Add PRODUCTS********************
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USERS_REQUEST = "ADD_USERS_REQUEST";
export const ADD_USERS_FAIL = "ADD_USERS_FAIL";

// ***********************Add USERS********************
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

// ***********************Update USERS Status********************
export const UPDATE_USERS_STATUS_SUCCESS = "UPDATE_USERS_STATUS_SUCCESS";
export const UPDATE_USERS_STATUS_REQUEST = "UPDATE_USERS_STATUS_REQUEST";
export const UPDATE_USERS_STATUS_FAIL = "UPDATE_USERS_STATUS_FAIL";
