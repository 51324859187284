import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useTabStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // width: "250px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  content: {
    flex: "1 0 auto",
    paddingBottom: "0px",
  },
  cover: {
    borderRadius: "5px",
    margin: "10px",
    width: 90,
    backgroundSize: "contain",
  },
  controls: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },

  // stepper
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const classes = useTabStyles();
  const {
    children,
    value,
    index,
    categoryItems,
    selectedItems,
    handleSetSelectedItems,
    setOpenSnackbar,
    ...other
  } = props;
  return (
    // <Grid item style={{ flex: "1" }}>
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={2} style={{ padding: 10 }}>
          {categoryItems.map((categoryItem) => {
            const isItemSelected = selectedItems.find(
              (x) => x._id === categoryItem._id
            );
            const updateItemQuantity = (quantity) => {
              if (quantity > 0) {
                if (isItemSelected) {
                  isItemSelected.quantity = quantity;
                  handleSetSelectedItems(isItemSelected);
                } else {
                  let newSelectedItem = {
                    _id: categoryItem._id,
                    quantity,
                    name: categoryItem.name,
                    img: categoryItem.img,
                    volume: categoryItem.volume,
                  };
                  handleSetSelectedItems(newSelectedItem);
                }
              } else {
                setOpenSnackbar({
                  open: true,
                  severity: "error",
                  message: "Please remove item from list",
                });
              }
            };

            return (
              <Grid item xs={12} sm={6} key={categoryItem._id}>
                <Card elevation={2} variant="outlined" className={classes.root}>
                  <CardMedia
                    className={classes.cover}
                    image={`${process.env.REACT_APP_HOST}${categoryItem.image}`}
                    title={categoryItem.name}
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography
                        variant="button"
                        color="textSecondary"
                        style={{ textTransform: "capitalize" }}
                      >
                        {categoryItem.name}
                      </Typography>
                      <br />
                      <Typography variant="caption" color="textSecondary">
                        Volume: {categoryItem.volume} m<sup>3</sup>
                      </Typography>
                    </CardContent>
                    <div className={classes.controls}>
                      <IconButton
                        aria-label="remove"
                        onClick={() =>
                          updateItemQuantity(
                            isItemSelected && isItemSelected.quantity - 1
                          )
                        }
                        disabled={!isItemSelected}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="button" color="textSecondary">
                        {isItemSelected ? isItemSelected.quantity : 0}
                      </Typography>
                      <IconButton
                        aria-label="add"
                        onClick={() =>
                          updateItemQuantity(
                            isItemSelected ? isItemSelected.quantity + 1 : 1
                          )
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
    // </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
