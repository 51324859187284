import React, { useEffect, useState } from "react";
import {
  Button,
  Fade,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../reduxState/aciton/authAction";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";

const useStyle = makeStyles((theme) => ({
  background: {
    color: "#e6e6e6",
    margin: 0,
  },
  Paper: {
    width: "580px",
    marginTop: "10em",
    color: "#f6f6f6",
    borderRadius: "17px",
    padding: "2em",
  },
  Heading: {
    marginTop: "1em",
    fontSize: "1.3em",
    fontFamily: "Poppins, sans-serif",
    color: `${theme.palette.common.pink}`,
  },
  Link: { textDecoration: "none" },
  Button: {
    borderRadius: "20px",
    padding: "13px",
    backgroundColor: `${theme.palette.common.pink}`,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login() {
  const classes = useStyle();
  let navigate = useNavigate();

  // ***************************************state *********************************************
  const [formData, setformData] = useState({
    email: "",
  });
  const [validationHelper, setValidationHelper] = useState({
    emailHelper: "",
  });
  const { email } = formData;
  const { emailHelper } = validationHelper;

  // Snackbar
  const [openSanckbar, setOpenSnackbar] = useState({
    open: false,
    severity: "",
  });

  // *********************************OnChange handle**********************************************
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
    let valid;

    switch (e.target.name) {
      case "email":
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          e.target.value
        );
        if (!valid) {
          setValidationHelper({
            ...validationHelper,
            emailHelper: "Invalid Email",
          });
        } else {
          setValidationHelper({ ...validationHelper, emailHelper: "" });
        }
        break;
      default:
        break;
    }
  };

  // ***************************Redux State***************************
  const dispatch = useDispatch();
  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { success, error: errorSuccess } = userResetPassword;

  // ***************************Validation**************************

  const Validation = () => {
    let isError = true;

    if (email.length === 0) {
      isError = false;

      validationHelper.emailHelper = "Email is Required";
    }

    setValidationHelper({
      ...validationHelper,
    });

    return isError;
  };

  // *******************************onSubmit ******************************************************
  const onSubmit = (e) => {
    let err = Validation();

    if (err) {
      e.preventDefault();
      dispatch(resetPassword(email));
    }
  };

  // *************************************useEffect***********************************************
  useEffect(() => {
    if (success) {
      navigate("/dashboard");
    }
  }, [success, navigate]);

  // *************************************useEffect***********************************************
  useEffect(() => {
    if (errorSuccess) {
      setOpenSnackbar({
        open: true,
        severity: "error",
        message: errorSuccess
          ? errorSuccess?.data?.error
          : "Check you internet connection!",
      });
    }
  }, [errorSuccess]);

  return (
    <Grid item container className={classes.background} justify="center">
      <Grid
        item
        container
        component={Paper}
        elevation={3}
        className={classes.Paper}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justifyContent="center"
          className={classes.Heading}
        >
          <Typography variant="h4">Forget your Password ?</Typography>
        </Grid>
        <Grid item style={{ marginTop: "3em" }}>
          <Typography
            variant="subtitle1"
            style={{ textAlign: "-webkit-center" }}
          >
            Don't worry! Just fill your email and we'll send you a link to reset
            your password
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: "3em" }}
        >
          <Grid item container justify="center">
            <TextField
              id="Email"
              variant="outlined"
              placeholder="Email"
              fullWidth
              inputProps={{
                style: {
                  padding: 13,
                  fontSize: "1.1rem",
                },
              }}
              name="email"
              value={email}
              error={emailHelper.length !== 0}
              helperText={emailHelper}
              onChange={onChange}
            />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: "3em" }}
          >
            <Button
              onClick={onSubmit}
              fullWidth
              variant="contained"
              className={classes.Button}
            >
              Reset
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          style={{ marginTop: "3em" }}
        >
          <Link to="/Login" className={classes.Link}>
            <Typography variant="h5">Back to Signin</Typography>
          </Link>
        </Grid>
      </Grid>

      {/* ********************************Snackbar ********************************* */}
      <Snackbar
        open={openSanckbar.open}
        autoHideDuration={6000}
        TransitionComponent={Fade}
        onClose={() => {
          setOpenSnackbar({ open: false });
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar({ open: false });
          }}
          severity={openSanckbar.severity}
        >
          {openSanckbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
export default Login;
