// ***********************Add PRODUCTS********************
export const ADD_PRODUCTS_SUCCESS = "ADD_PRODUCTS_SUCCESS";
export const ADD_PRODUCTS_REQUEST = "ADD_PRODUCTS_REQUEST";
export const ADD_PRODUCTS_FAIL = "ADD_PRODUCTS_FAIL";

// ***********************Add PRODUCTS********************
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

// ***********************delete PRODUCTS********************
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";
export const DELETE_PRODUCTS_REQUEST = "DELETE_PRODUCTS_REQUEST";
export const DELETE_PRODUCTS_FAIL = "DELETE_PRODUCTS_FAIL";
