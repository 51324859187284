import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import Card from "./card/Card";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../BreadCrum/BreadCrum";

import swal from "sweetalert";
// import { GetCategoryAction } from '../../reduxState/aciton/CategoryAction';
import { AddCategoryAction } from "../../reduxState/aciton/categoryAction";

const useStyles = makeStyles((theme) => ({
  divider: {
    // Theme Color, or use css color in quote
    background: "#0000",
  },
  padding: {
    padding: ".5rem",
  },

  dropZone: {
    height: "10px",
    fullWidth: "true",
  },
  previewContainer: {
    container: "true",
    width: "100%",
    height: "100%",
  },
  preview: {
    //width: '100%',
    //height: '100%',
    //item: 'true',
    xs: "12",
  },
  previewImg: {
    //height: '100%',
    //width: '100%',
  },
}));

function CreateCategory() {
  const classes = useStyles();

  // ***********************State **************************
  const [formData, setformData] = useState({
    name: "",
  });

  const { name } = formData;

  const [formValidation, setformValidation] = useState({
    nameHelper: "",
  });

  // ******************* Redux State********************************
  const dispatch = useDispatch();
  //   const addProduct = useSelector((state) => state.addProduct);
  //   const { success } = addProduct;

  // *********************** handle ***********************************
  const onValidation = () => {
    let isError = true;
    for (var key in formData) {
      if (formData[key] === "") {
        formValidation[key + "Helper"] = "This Filed is Required";
        isError = false;
      } else {
        formValidation[key + "Helper"] = "";
      }
    }

    setformValidation({ ...formValidation });
    // console.log(formValidation);
    return isError;
  };

  const createCategory = () => {
    let err = onValidation();
    console.log({ name }, "meta");
    if (err) {
      console.log({ name }, "user data to inser");
      dispatch(AddCategoryAction({ categoryName: name }));
    }
  };

  // ****************************** UseEffect **************************************

  return (
    <Grid>
      <BreadCrum />
      <Grid item container>
        <Card
          formData={formData}
          formValidation={formValidation}
          setformData={setformData}
        />
      </Grid>
      <Grid
        item
        container
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
        alignItems="center"
        justify="center"
      >
        <Button variant="contained" color="secondary" onClick={createCategory}>
          {" "}
          Create Category
        </Button>
      </Grid>
    </Grid>
  );
}

export default CreateCategory;
