import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceFreeTableHeader from "./InvoiceFreeTableHeader";
import InvoiceFreeTableRow from "./InvoiceFreeTableRow";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#C12B2D",
  },
});

const InvoiceItemsTable = ({ invoice }) => {
  const tableRowsCount = invoice.items.length + 2;

  return (
    <View style={styles.tableContainer}>
      <InvoiceFreeTableHeader />
      <InvoiceFreeTableRow items={invoice.freeMaterial} />
    </View>
  );
};

export default InvoiceItemsTable;
