import React, { useState, useEffect } from "react";
import {
  Divider,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import { generateStylesAppointment } from "../../Helpers/StylesGenerator";
import instance from "../../../../Config/axios";

const moment = require("moment");

const useStyle = makeStyles((theme) => generateStylesAppointment(theme));

export default function Appointment({ handlers }) {
  const classes = useStyle();
  const matchesSm = useMediaQuery("(min-width:600px)");

  const { date, changeDate, selectedValue, setSelectedValue } = handlers;
  // const [date, changeDate] = useState(moment());
  // const [selectedValue, setSelectedValue] = React.useState("");
  const [slots, setSlots] = useState([]);
  const [isDateAvailable, setIsDateAvailable] = useState(false);
  const [allBookedDates, setAllBookedDates] = useState([]);

  const getTimeslots = async (date) => {
    try {
      const { data } = await instance.post("/orders/get-time-slots", { date });
      console.log(data, "data");
      setSlots(data.data.timeSlots);
      //setIsDateAvailable(data.data.isBooked);
      //setAllBookedDates(data.data.allBookedDates);
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleChange = (value) => {
    console.log(value);
    setSelectedValue(value);
  };

  const dateCheckForDisable = (date) => {
    // console.log(date, "date is here");
    const findDate = allBookedDates.find((x) => {
      if (moment(new Date(x.dateOfMove)).isSame(new Date(date.format()))) {
        console.log("*********************************");
        return true;
      }
      // new Date(x.dateOfMove) == new Date(date.format())
    });
    console.log(findDate, "findDate");
    if (findDate) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getTimeslots(date.format());
  }, [date]);

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      justify="center"
      //   alignItems="flex-start"
      //   className={classes.inputContainer}
    >
      <Typography
        variant="button"
        color="textSecondary"
        style={{ textTransform: "capitalize" }}
      >
        Please select your preferred moving date. If you don't know the exact
        date, please select a tentative date (we will change it later)
      </Typography>
      <Grid item container>
        <Grid item xs={12} sm={6} className={classes.datePickerStyles}>
          <DatePicker
            autoOk
            orientation={matchesSm ? "landscape" : "portrait"}
            variant="static"
            openTo="date"
            value={date}
            //shouldDisableDate={dateCheckForDisable}
            onChange={changeDate}
            minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <List
            dense={true}
            style={{
              width: "250px",
              minWidth: "150px",
              background: "#f2f2f2",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Typography
              variant="button"
              color="textSecondary"
              style={{ textTransform: "capitalize" }}
            >
              Select Time slot
            </Typography>
            {slots.length
              ? slots?.map((slot) => {
                  return (
                    <ListItem
                      key={slot.id}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "10px",
                        background: "white",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        if (!slot.isBooked) {
                          handleChange(slot.id);
                        }
                      }}
                      disabled={isDateAvailable || slot.isBooked}
                    >
                      <ListItemText
                        style={{ color: "rgb(145 145 145)" }}
                        primary={
                          slot.id === 1
                            ? "Morning (7 - 9)"
                            : slot.id === 2
                            ? "Afternoon (12 - 3)"
                            : "Evening (5 - 7)"
                        }
                      />
                      <ListItemSecondaryAction
                        style={{
                          borderLeft: "1px solid rgb(207 207 207 / 87%)",
                        }}
                      >
                        <Radio
                          checked={selectedValue == slot.id}
                          onChange={(e) => handleChange(e.target.value)}
                          value={slot.id}
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "E" }}
                          size="small"
                          disabled={isDateAvailable || slot.isBooked}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
              : "Fetching Slots"}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
