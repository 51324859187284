import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Information from "../Information";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";

export default function InformationDialog({
  handlers,
  handleClickOpen,
  handleClose,
  submitOrder,
  open,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(fullScreen);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        fullScreen={fullScreen}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Confirm your order</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Information handlers={handlers} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => submitOrder()} color="primary">
            Confirm Order
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
