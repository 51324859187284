import instance from "../../../../Config/axios";

let MAX_TRUCK_SIZE = 20;
let COST_PER_KM = 0.39;
let COST_PER_FLOOR = 25;
let COST_MOVER_PER_MIN = 0.5;
let COST_CARGO_PER_HOUR = 80;

// ************************** UNDER 50KM ONWARDS **************************
let TRUCK_COST_0_50_0_10 = 80;
let SERVICE_TIME_0_50_0_10 = 80;

let TRUCK_COST_0_50_11_15 = 80;
let SERVICE_TIME_0_50_11_15 = 80;

let TRUCK_COST_0_50_16_20 = 80;
let SERVICE_TIME_0_50_16_20 = 80;

let TRUCK_COST_0_50_21_30 = 80;
let SERVICE_TIME_0_50_21_30 = 80;

let TRUCK_COST_0_50_31 = 80;
let SERVICE_TIME_0_50_31 = 80;

// ************************** 50KM ONWARDS **************************
let TRUCK_COST_50_0_10 = 80;
let SERVICE_TIME_50_0_10 = 80;

let TRUCK_COST_50_11_15 = 80;
let SERVICE_TIME_50_11_15 = 80;

let TRUCK_COST_50_16_20 = 80;
let SERVICE_TIME_50_16_20 = 80;

let TRUCK_COST_50_21_30 = 80;
let SERVICE_TIME_50_21_30 = 80;

let TRUCK_COST_50_31 = 80;
let SERVICE_TIME_50_31 = 80;

const orderCalculations = async ({ args }) => {
  const { data } = await instance.get(`/configurations`);
  console.log(data.data.configurations, "data is from back");
  let configurations = data.data.configurations;
  configurations.forEach((ele) => {
    switch (ele._id) {
      case "61e3da28d4d42a205fd96c50":
        COST_PER_KM = ele.value;
        break;
      case "61e3d9fdd4d42a205fd96c46":
        COST_PER_FLOOR = ele.value;
        break;
      case "61e3da4ad4d42a205fd96c52":
        COST_MOVER_PER_MIN = ele.value;
        break;
      case "61e3da5bd4d42a205fd96c54":
        COST_CARGO_PER_HOUR = ele.value;
        break;
      case "61e3db8dd4d42a205fd96c56":
        TRUCK_COST_0_50_0_10 = ele.value;
        break;
      case "61e3db9ed4d42a205fd96c58":
        SERVICE_TIME_0_50_0_10 = ele.value;
        break;
      case "61e3dbc0d4d42a205fd96c5a":
        TRUCK_COST_0_50_11_15 = ele.value;
        break;
      case "61e3dbd9d4d42a205fd96c5c":
        SERVICE_TIME_0_50_11_15 = ele.value;
        break;
      case "61e3dbf2d4d42a205fd96c5e":
        SERVICE_TIME_0_50_16_20 = ele.value;
        break;
      case "61e3dbfdd4d42a205fd96c60":
        TRUCK_COST_0_50_16_20 = ele.value;
        break;
      case "61e3dc12d4d42a205fd96c62":
        TRUCK_COST_0_50_21_30 = ele.value;
        break;
      case "61e3dc40d4d42a205fd96c64":
        SERVICE_TIME_0_50_21_30 = ele.value;
        break;
      case "61e3dc57d4d42a205fd96c66":
        SERVICE_TIME_0_50_31 = ele.value;
        break;
      case "61e3dc62d4d42a205fd96c68":
        TRUCK_COST_0_50_31 = ele.value;
        break;
      case "61e3ddc5d4d42a205fd96c6a":
        TRUCK_COST_50_0_10 = ele.value;
        break;
      case "61e3ddfcd4d42a205fd96c6c":
        TRUCK_COST_50_11_15 = ele.value;
        break;
      case "61e3de06d4d42a205fd96c6e":
        TRUCK_COST_50_16_20 = ele.value;
        break;
      case "61e3de1fd4d42a205fd96c70":
        TRUCK_COST_50_21_30 = ele.value;
        break;
      case "61e3de44d4d42a205fd96c72":
        TRUCK_COST_50_31 = ele.value;
        break;
      case "61e3de62d4d42a205fd96c74":
        SERVICE_TIME_50_0_10 = ele.value;
        break;
      case "61e3de73d4d42a205fd96c76":
        SERVICE_TIME_50_11_15 = ele.value;
        break;
      case "61e3de81d4d42a205fd96c78":
        SERVICE_TIME_50_16_20 = ele.value;
        break;
      case "61e3de8fd4d42a205fd96c7a":
        SERVICE_TIME_50_21_30 = ele.value;
        break;
      case "61e3dea1d4d42a205fd96c7c":
        SERVICE_TIME_50_31 = ele.value;
        break;
      default:
        console.log("nothing found");
    }
  });

  const {
    cargoHours,
    numberOfWorkers,
    selectedItemsVolume,
    addressDistance,
    totalDistance,
    totalStairs,
  } = args;
  const totalDrivingTime = totalDistance / 1000;
  let truckCost = null;
  let serviceTime = null;
  if (addressDistance <= 50000) {
    if (selectedItemsVolume > 0 && selectedItemsVolume <= 10) {
      truckCost = TRUCK_COST_0_50_0_10;
      serviceTime = SERVICE_TIME_0_50_0_10;
    } else if (selectedItemsVolume > 10 && selectedItemsVolume <= 15) {
      truckCost = TRUCK_COST_0_50_11_15;
      serviceTime = SERVICE_TIME_0_50_11_15;
    } else if (selectedItemsVolume > 15 && selectedItemsVolume <= 20) {
      truckCost = TRUCK_COST_0_50_16_20;
      serviceTime = SERVICE_TIME_0_50_16_20;
    } else if (selectedItemsVolume > 20 && selectedItemsVolume <= 30) {
      truckCost = TRUCK_COST_0_50_21_30;
      serviceTime = SERVICE_TIME_0_50_21_30;
    } else {
      truckCost = TRUCK_COST_0_50_31;
      serviceTime = SERVICE_TIME_0_50_31;
    }
  } else {
    if (selectedItemsVolume > 0 && selectedItemsVolume <= 10) {
      truckCost = TRUCK_COST_50_0_10;
      serviceTime = SERVICE_TIME_50_0_10;
    } else if (selectedItemsVolume > 10 && selectedItemsVolume <= 15) {
      truckCost = TRUCK_COST_50_11_15;
      serviceTime = SERVICE_TIME_50_11_15;
    } else if (selectedItemsVolume > 15 && selectedItemsVolume <= 20) {
      truckCost = TRUCK_COST_50_16_20;
      serviceTime = SERVICE_TIME_50_16_20;
    } else if (selectedItemsVolume > 20 && selectedItemsVolume <= 30) {
      truckCost = TRUCK_COST_50_21_30;
      serviceTime = SERVICE_TIME_50_21_30;
    } else if (selectedItemsVolume > 30) {
      truckCost = TRUCK_COST_50_31;
      serviceTime = SERVICE_TIME_50_31;
    }
  }
  console.log(truckCost, "truckcost");
  const totalServiceTime = serviceTime + totalDrivingTime;
  const totalKMCost = (totalDistance / 1000) * COST_PER_KM;

  const totalWorkersCost =
    numberOfWorkers * totalServiceTime * COST_MOVER_PER_MIN;
  const totalStairsCost = totalStairs * COST_PER_FLOOR;
  const totalCargoCost = cargoHours * COST_CARGO_PER_HOUR;
  const totalTruckCost = truckCost;
  const totalOrderCost =
    totalKMCost +
    totalWorkersCost +
    totalStairsCost +
    totalTruckCost +
    totalCargoCost;
  console.log({
    totalKMCost,
    totalWorkersCost,
    totalStairsCost,
    totalTruckCost,
  });
  console.log({
    numberOfWorkers,
    totalServiceTime,
    COST_MOVER_PER_MIN,
  });
  console.log(totalOrderCost, "order cost");
  return {
    totalServiceTime,
    totalOrderCost,
    totalKMCost,
    totalWorkersCost,
    totalStairsCost,
    totalTruckCost,
    totalCargoCost,
  };
};

export { orderCalculations };
