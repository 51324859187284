import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../component/Login/index";
import Register from "../component/Register/index";
import Layout from "../component/ui/Layout";
import ForgetPassword from "../component/ResetPassword/Email";
import ResetPassword from "../component/ResetPassword/RestoredPassword";

import CalculateVolumeStepper from "../component/CalculateVolumeStepper/index";
import VolumeCalculator from "../component/VolumeCalculator/index";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../component/ui/theme";

const CustomRouter = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/calculate-volume"
            element={<CalculateVolumeStepper />}
          />
          <Route
            path="/furniture-calculate-volume"
            element={<VolumeCalculator />}
          />
          <Route path="/" element={<Navigate to="/calculate-volume" />} />
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default CustomRouter;
