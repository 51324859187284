import { createTheme } from "@material-ui/core/styles";

const Blue = "#21242e";
const Orange = "#FFBA60";
const lightBlue = `#1976D2`;
const Grey = "#616161";

export default createTheme({
  palette: {
    common: {
      Blue: `${Blue}`,
      Orange: `${Orange}`,
      Gray: `${Grey}`,
    },
    primary: {
      main: `${Blue}`,
      contrastText: "#fff",
    },
    secondary: {
      main: `${lightBlue}`,
      contrastText: "#fff",
    },
  },
  typography: {
    shipmentHeading: {
      fontFamily: "Raleway, Sans-serif",
      fontSize: "50px",
      fontWeight: "700",
    },
    sideBar: {
      fontFamily: "Raleway, Sans-serif",
      textTransform: "capitalize",
      fontSize: "0.875em",
    },
    tab: {
      fontFamily: "Raleway, Sans-serif",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    },
    estimate: {
      fontFamily: "Raleway, Sans-serif",
      fontSize: "1rem",
      textTransform: "none",
    },
    h2: {
      fontFamily: "Raleway, Sans-serif",
      fontSize: "20px",
      fontWeight: "700",
    },
    h3: {
      fontFamily: "Raleway, Sans-serif",
      fontSize: "14px",

      lineHeight: 1,
    },
    h4: {
      fontFamily: "Raleway, Sans-serif !important",
      fontSize: "1.75rem",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Raleway, Sans-serif",
      fontSize: "20px",
      // fontWeight: 'bold',
      // color: '#666666',
    },
    h6: {
      fontFamily: "Raleway, Sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: 1,
    },
    subtitle1: {
      fontFamily: "Raleway, sans-serif",

      fontSize: "1.25rem",
      fontWeight: 300,
    },
    subtitle2: {
      fontFamily: "Raleway, sans-serif",
      fontSize: ".9rem",
      fontWeight: 300,
    },

    body1: {
      fontFamily: "Raleway, sans-serif",
      fontSize: "1.25rem",
      fontWeight: 300,
    },
    button: {
      fontFamily: "Raleway, sans-serif",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        // textTransform: "capitalize",
        // fontFamily: "Roboto,Helvetica,Arial, sans-serif",
        // fontWeight: 700,
      },
      // labelIcon: {
      //   minHeight: "auto",
      // },
      wrapper: {
        // padding: "10px",
        // paddingLeft: "20px",
        // paddingRight: "20px",
        // alignItems: "center",
        // flexDirection: "row",
        // justifyContent: "flexStart",
        // "&:hover": {
        //   background: "white",
        // },
      },
    },
    // MuiSvgIcon: {
    //   root: {
    //     height: "0.8em",
    //     width: "0.8em",
    //     marginRight: "10px",
    //   },
    // },

    // *********************** calculate volume form ***********************
    // MuiRadio: {
    //   root: {
    //     color: "white",
    //   },
    // },
    // MuiInputLabel: {
    //   root: {
    //     color: "#fff !important",
    //     fontSize: "14px",
    //   },
    // },
    // MuiFocused: {
    //   color: "#fff",
    // },
    // MuiOutlinedInput: {
    //   root: {
    //     color: "#fff !important",
    //     fontSize: "14px",
    //     borderRadius: 0,
    //   },
    //   notchedOutline: {
    //     borderColor: "#fff !important",
    //   },
    // },
    // MuiInput: {
    //   root: {
    //     color: "#fff",
    //     fontWeight: 300,
    //     fontSize: "10px",
    //   },
    //   underline: {
    //     "&:before": {
    //       // borderBottom: `1px solid ${Grey}`,
    //     },
    //     "&:hover:not($disabled):not($focused):not($error):before": {
    //       // borderBottom: `1px solid ${Grey}`,
    //     },
    //   },
    // },
  },
});
