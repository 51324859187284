import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import GoogleMapReact from "google-map-react";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const Marker = ({ text }) => <div>{text}</div>;

export default function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLng, setSelectedLng] = useState("");
  const handleClose = () => {
    onClose({ lat: selectedLat, lng: selectedLng });
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };
  const _onClick = ({ x, y, lat, lng, event }) => {
    console.log(x, y, lat, lng, event);
    setSelectedLat(lat);
    setSelectedLng(lng);
  };

  useEffect(() => {
    if (selectedValue) {
      setSelectedLng(selectedValue.lng);
      setSelectedLat(selectedValue.lat);
    }
  }, []);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="map-dialog"
      open={open}
      fullScreen
    >
      <DialogTitle id="map-dialog">Select a location</DialogTitle>
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyATHgFeUY0gt1jd8oJpWSr99fqayTEHzUY" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onClick={_onClick}
        >
          <Marker lat={selectedLat} lng={selectedLng} text="My Marker" />
        </GoogleMapReact>
      </div>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
};
