import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import instance from "../Config/axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reduxState/aciton/authAction";
// import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";

const cookies = new Cookies();

export const PrivateRoute = ({ element: Element, path, isAdmin, ...props }) => {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAdminState, setIsAdminState] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  useEffect(() => {
    let token = cookies.get("token");
    let userInfoData = cookies.get("userData");
    console.log(userInfoData, "user info data");
    if (token && userInfoData) {
      // send jwt to API to see if it's valid
      try {
        instance
          .get(`/auth/verify-token`)
          .then((res) => {
            if (isAdmin) {
              if (userInfoData.isAdmin) {
                setIsAuthorized(true);
              }
              setAuth(true);
              setIsTokenValidated(true);
            } else {
              setAuth(true);
              setIsTokenValidated(true);
            }
            setIsAdminState(isAdmin);
          })
          .catch((err) => {
            dispatch(logout());
            setAuth(false);
            setIsTokenValidated(true);
          });
      } catch (err) {
        dispatch(logout());
        setAuth(false);
        setIsTokenValidated(true);
      }
    } else {
      setIsTokenValidated(true); // in case there is no token
    }
  }, []);

  if (!isTokenValidated) {
    return (
      <Grid item container alignItems="center" justify="center">
        Loading.......
        {/* your Loader
         */}
      </Grid>
    );
  } // or some kind of loading animation

  if (auth) {
    if (isAdminState) {
      if (isAuthorized) {
        return <Route path={path} element={<Element {...props} />} />;
      } else {
        return <Navigate to="/dashboard/not-authorized" />;
      }
    } else {
      return <Route path={path} element={<Element {...props} />} />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }
};
