import React from "react";
import { Page, Document, Image, StyleSheet } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceSelectedItemsTable from "./InvoiceSelectedItemsTable";
import InvoiceFreeItemsTable from "./InvoiceFreeItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import logo from "./sonicmovers-logo.jpg";
import main from "./main.jpg";
import OrderDetails from "./OrderDetails";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 5 * 25,
    height: 3 * 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const Invoice = ({ invoice }) => (
  <Document>
    {/* <Page size="A4" style={styles.page}>
      <Image src={main} />
    </Page> */}
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <InvoiceTitle title="Offer" />
      <InvoiceNo invoice={invoice} />
      <BillTo invoice={invoice} />
      <InvoiceItemsTable invoice={invoice} />
      <OrderDetails invoice={invoice} />
    </Page>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <InvoiceTitle title="Moving Items List" />
      <InvoiceSelectedItemsTable invoice={invoice} />
      <InvoiceFreeItemsTable invoice={invoice} />
      <InvoiceThankYouMsg />
    </Page>
  </Document>
);

export default Invoice;
