import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../BreadCrum/BreadCrum";
import instance from "../../Config/axios";
import Table from "../Table/index";

import {
  GetSettingAction,
  UpdateSettingItemValue,
} from "../../reduxState/aciton/settingAction";

const useStyles = makeStyles((theme) => ({
  root: { padding: "1rem" },
  headerFont: {
    fontSize: "1.1rem",
    fontFamily: "sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

function Setting() {
  const classes = useStyles();
  // ******************* useState **********************************
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(100);
  const [data, setData] = useState({});

  // ******************* Redux State********************************
  const dispatch = useDispatch();
  const getSettings = useSelector((state) => state.getSettings);
  console.log(getSettings, "getSettings");
  const { settings } = getSettings;
  const updateSettings = useSelector((state) => state.updateSettings);
  const { message } = updateSettings;

  // ***************************Handle*********************************

  // const GetCoverImage = async () => {
  //   let coverPhoto = {};
  //   if (settings.docs) {
  //     await settings.docs.map((x, index) =>
  //       instance.get(`/api/settings/photo/${x.coverPhoto}`).then((x) => {
  //         settings.docs[index].coverPhoto = x.data.imageView;
  //         coverPhoto = { ...settings };
  //         setData(coverPhoto);
  //       })
  //     );
  //   }
  // };

  const handleLive = (id) => {};
  const handleDelisted = (id) => {};
  const handleUpdate = (id, value) => {
    dispatch(UpdateSettingItemValue({ settingId: id, value }));
  };
  // ******************** useEffect ***********************************
  useEffect(() => {
    dispatch(GetSettingAction(Page, Limit));
  }, []);

  useEffect(() => {
    console.log(settings, "settings");
    if (settings?.length > 0) {
      setData(settings);
      //   GetCoverImage();
    }
  }, [settings]);

  useEffect(() => {
    dispatch(GetSettingAction(Page, Limit));
  }, [message]);
  console.log("jkjklkllkj");
  const SettingTable = [
    { id: "name", label: "Name", minWidth: 10 },
    { id: "value", label: "Value", minWidth: 10 },
    { id: "settingAction", label: "Action", minWidth: 140 },
  ];

  return (
    <Grid item container>
      <BreadCrum />

      <Grid
        item
        container
        component={Paper}
        elevation={0}
        className={classes.root}
      >
        <Typography className={classes.headerFont}>Settings List</Typography>

        <Grid item container style={{ marginTop: "1rem" }}>
          {data?.length > 0 ? (
            <Table
              tableHead={SettingTable}
              tableData={data}
              Page={Page}
              setPage={setPage}
              Limit={Limit}
              setLimit={setLimit}
              totalPages={data.totalPages}
              handleUpdate={handleUpdate}
              //   handleLive={handleLive}
              //   handleDelisted={handleDelisted}
              //Edit modal
            />
          ) : (
            "No Setting Found"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Setting;
