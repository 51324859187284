import React from "react";
import CustomRouter from "./Route/CustomRouter";
import CustomRouterPublic from "./Route/CustomRouterPublic";

function App() {
  return (
    <>
      <CustomRouter />
      <CustomRouterPublic />
    </>
  );
}

export default App;
