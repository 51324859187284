import instance from "../../Config/axios";
import { setAlert } from "./AlertAction";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const {
  ADD_CATEGORIES_REQUEST,
  ADD_CATEGORIES_FAIL,
  ADD_CATEGORIES_SUCCESS,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
} = require("../constants/categoryConstant");

// ******************************* Add CATEGORIES ****************************************
const AddCategoryAction = (category) => async (dispatch) => {
  try {
    dispatch({ type: ADD_CATEGORIES_REQUEST, payload: category });
    // create category
    console.log(category, "form Action");
    const productCreate = await instance.post(
      "/categories/create-category",
      category
    );
    setAlert("Good job!", "Product Added Successfully!", "success");
    dispatch({
      type: ADD_CATEGORIES_SUCCESS,
      payload: productCreate.data,
      success: true,
    });
  } catch (error) {
    console.log(error, "error");
    setAlert("Oops!", "Something wrong, Please try later!", "error");

    dispatch({ type: ADD_CATEGORIES_FAIL, payload: error.response });
  }
};

const GetCategoryAction = (Page, Limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORIES_REQUEST });
    let userInfoData = cookies.get("userData");
    if (userInfoData) {
      const { isAdmin } = userInfoData;
      let data = null;
      if (isAdmin) {
        data = await instance.get(`/categories?page=${Page}&perPage=${Limit}`);
      }
      console.log(data, "data is here");
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: data?.data?.data?.categories,
        success: true,
      });
    } else {
      setAlert("Oops!", "Something wrong, Please try later!", "error");
    }
  } catch (error) {
    setAlert("Oops!", "Something wrong, Please try later!", "error");
    dispatch({ type: GET_CATEGORIES_FAIL, payload: error.response });
  }
};

export { AddCategoryAction, GetCategoryAction };
