import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../BreadCrum/BreadCrum";
import Table from "../Table/index";

import { GetCategoryAction } from "../../reduxState/aciton/categoryAction";

const useStyles = makeStyles((theme) => ({
  root: { padding: "1rem" },
  headerFont: {
    fontSize: "1.1rem",
    fontFamily: "sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

function Category() {
  const classes = useStyles();
  // ******************* useState **********************************
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(10);
  const [data, setData] = useState({});

  // ******************* Redux State********************************
  const dispatch = useDispatch();
  const getCategory = useSelector((state) => state.getCategory);
  const { categories } = getCategory;

  // ***************************Handle*********************************

  // const GetCoverImage = async () => {
  //   let coverPhoto = {};
  //   if (categories.docs) {
  //     await categories.docs.map((x, index) =>
  //       instance.get(`/api/categories/photo/${x.coverPhoto}`).then((x) => {
  //         categories.docs[index].coverPhoto = x.data.imageView;
  //         coverPhoto = { ...categories };
  //         setData(coverPhoto);
  //       })
  //     );
  //   }
  // };

  const handleLive = (id) => {};
  const handleDelisted = (id) => {};
  // ******************** useEffect ***********************************
  useEffect(() => {
    dispatch(GetCategoryAction(Page, Limit));
  }, []);

  useEffect(() => {
    console.log(categories, "categories");
    if (categories?.length > 0) {
      setData(categories);
      //   GetCoverImage();
    }
  }, [categories]);

  const CategoryTable = [
    { id: "_id", label: "Id", minWidth: 10 },
    { id: "name", label: "Name", minWidth: 10 },
    { id: "status", label: "Status", minWidth: 10 },
    { id: "createdAt", label: "created", minWidth: 10 },
    // { id: "action", label: "Action", minWidth: 140 },
  ];

  return (
    <Grid item container>
      <BreadCrum />

      <Grid
        item
        container
        component={Paper}
        elevation={0}
        className={classes.root}
      >
        <Typography className={classes.headerFont}>Category List</Typography>

        <Grid item container style={{ marginTop: "1rem" }}>
          {data?.length > 0 ? (
            <Table
              tableHead={CategoryTable}
              tableData={data}
              Page={Page}
              setPage={setPage}
              Limit={Limit}
              setLimit={setLimit}
              totalPages={data.totalPages}
              //   handleLive={handleLive}
              //   handleDelisted={handleDelisted}
              //Edit modal
            />
          ) : (
            "No Category Found"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Category;
