import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../BreadCrum/BreadCrum";
import instance from "../../Config/axios";
import TableCollapsible from "../TableCollapsible/index";
import SearchIcon from "@material-ui/icons/Search";
import {
  GetOrderAction,
  UpdateOrderStatus,
  UpdateOrderItemStatus,
} from "../../reduxState/aciton/orderAction";
import OrderSearch from "./searchInput";

const useStyles = makeStyles((theme) => ({
  root: { padding: "1rem" },
  headerFont: {
    fontSize: "1.1rem",
    fontFamily: "sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

function Order() {
  const classes = useStyles();
  // ******************* useState **********************************
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(10);
  const [data, setData] = useState({});
  const [inp, setInp] = useState("");
  let searchName = "";
  const orderStatus = ["in_process", "delivered", "close"];
  const orderItemStatus = [
    "in_process",
    "recieved",
    "recieve",
    "requested",
    "out-for-pickup",
    "delivered",
  ];

  // ******************* Redux State********************************
  const dispatch = useDispatch();
  const getOrders = useSelector((state) => state.getOrders);
  const { orders } = getOrders;

  // ***************************Handle*********************************

  // const GetCoverImage = async () => {
  //   let coverPhoto = {};
  //   if (orders.docs) {
  //     await orders.docs.map((x, index) =>
  //       instance.get(`/api/orders/photo/${x.coverPhoto}`).then((x) => {
  //         orders.docs[index].coverPhoto = x.data.imageView;
  //         coverPhoto = { ...orders };
  //         setData(coverPhoto);
  //       })
  //     );
  //   }
  // };

  const handleLive = (id) => {};
  const handleDelisted = (id) => {};
  const changeOrderStatusClick = (orderId, status) => {
    console.log(orderId, "order id");
    console.log(status, "order id status");
    dispatch(UpdateOrderStatus({ orderId, status }));
    // dispatch(GetOrderAction(Page, Limit));
  };

  const changeOrderItemStatusClick = (orderId, statusToChange, status) => {
    dispatch(UpdateOrderItemStatus({ orderId, statusToChange, status }));
    dispatch(GetOrderAction(Page, Limit));
  };
  // ******************** useEffect ***********************************
  useEffect(() => {
    dispatch(GetOrderAction(Page, Limit));
  }, []);

  useEffect(() => {
    console.log(orders, "orders");
    if (orders?.length > 0) {
      setData(orders);
      //   GetCoverImage();
    }
  }, [orders]);

  const reset = () => {
    if (orders?.length > 0) {
      setData(orders);
      //   GetCoverImage();
    }
  };

  const handleSearch = (input) => {
    console.log(orders, "orders fetched");
    if (orders.length) {
      console.log(input, "input");
      const filteredOrders = orders?.filter((e) => {
        let reg = "\\b" + input + "\\b";
        if (e.name.match(new RegExp(reg, "i"))) {
          return true;
        }
        return false;
      });
      console.log(filteredOrders, "filteredOrders");
      setData(filteredOrders);
    }
  };

  const OrderTable = [
    { id: "invoiceNo", label: "Invoice No", minWidth: 10 },
    { id: "name", label: "Name", minWidth: 10 },
    { id: "email", label: "Email", minWidth: 10 },
    { id: "dateOfMove", label: "Move Date", minWidth: 10 },
    { id: "timeOfMove", label: "Shift", minWidth: 10 },
    { id: "isDrafted", label: "Drafted", minWidth: 10 },
    { id: "createdAt", label: "created", minWidth: 10 },
    { id: "actionBookUser", label: "Is Booked", minWidth: 10 },
    { id: "actionConfirmOrder", label: "Is Confirmed", minWidth: 10 },
    // { id: "actionOrderStatus", label: "Action", minWidth: 140 },
  ];

  return (
    <Grid item container>
      <BreadCrum />
      <OrderSearch handleSearch={handleSearch} reset={reset} />
      <Grid
        item
        container
        component={Paper}
        elevation={0}
        className={classes.root}
      >
        <Typography className={classes.headerFont}>Order List</Typography>
        <Grid item container style={{ marginTop: "1rem" }}>
          {data?.length > 0 ? (
            <TableCollapsible
              tableHead={OrderTable}
              tableData={data}
              Page={Page}
              setPage={setPage}
              Limit={Limit}
              setLimit={setLimit}
              totalPages={data.totalPages}
              orderStatus={orderStatus}
              changeOrderStatusClick={changeOrderStatusClick}
              changeOrderItemStatusClick={changeOrderItemStatusClick}
              orderItemStatus={orderItemStatus}
              //   handleLive={handleLive}
              //   handleDelisted={handleDelisted}
              //Edit modal
            />
          ) : (
            "No Order Found"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Order;
