import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import Cookies from "universal-cookie";

import {
  userLoginReducer,
  userRegisterReducer,
  userResetPasswordReducer,
  userPasswordRestoreReducer,
} from "./reduxState/reducers/authReducer";

import {
  AddUsersReducer,
  GetUsersReducer,
  UpdateUsersStatusReducer,
} from "./reduxState/reducers/userReducer";

import {
  GetCategoryReducer,
  AddCategoryReducer,
} from "./reduxState/reducers/categoryReducer";

import {
  GetProductsReducer,
  AddProductsReducer,
  DeleteProductsReducer,
} from "./reduxState/reducers/productReducer";

import {
  GetSettingsReducer,
  UpdateSettingsReducer,
} from "./reduxState/reducers/settingReducer";

import {
  AddOrdersReducer,
  GetOrdersReducer,
  UpdateOrdersStatusReducer,
  UpdateOrdersItemStatusReducer,
} from "./reduxState/reducers/orderReducer";

const cookies = new Cookies();

const userInfo = cookies.get("token") || null;

const initialState = {
  userSignin: { userInfo },
};

const reducer = combineReducers({
  // auth
  userSignin: userLoginReducer,
  userRegister: userRegisterReducer,
  userResetPassword: userResetPasswordReducer,
  userPasswordStore: userPasswordRestoreReducer,
  // users
  addUserReducer: AddUsersReducer,
  getUserReducer: GetUsersReducer,
  updateUsersStatusReducer: UpdateUsersStatusReducer,
  // products
  getProduct: GetProductsReducer,
  addProduct: AddProductsReducer,
  deleteProduct: DeleteProductsReducer,
  // orders
  getOrders: GetOrdersReducer,
  updateOrdersStatusReducer: UpdateOrdersStatusReducer,
  updateOrdersItemStatusReducer: UpdateOrdersItemStatusReducer,
  getCategory: GetCategoryReducer,
  addCategory: AddCategoryReducer,
  addOrder: AddOrdersReducer,
  // settings
  getSettings: GetSettingsReducer,
  updateSettings: UpdateSettingsReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
