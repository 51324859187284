import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import DashboardIcon from "@material-ui/icons/Dashboard";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import CategoryIcon from "@material-ui/icons/Category";

import MuiListItem from "@material-ui/core/ListItem";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import GroupIcon from "@material-ui/icons/Group";

import AccountTreeIcon from "@material-ui/icons/AccountTree";

import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import { Typography, withStyles } from "@material-ui/core";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.common.Blue,
  },

  nested: {
    paddingLeft: theme.spacing(4),
    textDecoration: "none",
  },
  linkStyle: {
    fontFamily: "Poppins, sans-serif",
    textDecoration: "none",
    color: "#f6f6f6",
  },
  subListBackground: {
    background: "#f6f6f6",
  },

  textStyle: {
    ...theme.typography.sideBar,
    color: "#f6f6f6",
  },
  iconStyle: {
    color: "#f6f6f6",
  },
}));

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(220, 220, 220,0.3)",
      color: "white",
    },
    "&$selected:hover": {
      backgroundColor: "rgba(220, 220, 220,0.2)",
      color: "white",
    },
    "&:hover": {
      backgroundColor: "rgba(220, 220, 220,0.1)",
      color: "white",
    },
  },
  selected: {},
})(MuiListItem);

const MainListItems = ({ drawerOpen }) => {
  const classes = useStyles();
  // const userSignin = useSelector((state) => state.userSignin);
  // const { loading, userInfoData, error: errorSuccess } = userSignin;
  const [userInfoData, setUserInfoData] = useState({});

  // ************************************* states **************************************
  const [openProducts, setOpenProducts] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [users, setUsers] = useState(false);
  const [orders, setOrders] = useState(false);
  const [menu, setMenu] = useState([]);
  const [tree, setTree] = useState(false);

  const [openOrders, setOpenOrders] = useState(false);

  const [drawer, setDrawer] = useState(drawerOpen);

  const [selectedIndex, setSelectedIndex] = React.useState();

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  // ************************************* handlers *************************************
  const handleClickProducts = () => {
    console.log("i am clicked");

    setOpenProducts(!openProducts);
    setOpenCategories(false);
    setUsers(false);
    setOrders(false);
    setOpenOrders(false);
    setOpenCategories(false);
    if (drawerOpen) {
    }
  };

  const handleClickCategories = () => {
    console.log("i am clicked");

    setOpenCategories(!openCategories);
    setUsers(false);
    setOrders(false);
    setOpenOrders(false);
    setOpenProducts(false);

    if (drawerOpen) {
    }
  };

  // const handleClickCategories = () => {
  //   if (drawerOpen) {
  //     setOpenCategories(!openCategories);
  //     setUsers(false);
  //     setOpenOrders(false);
  //     setOrders(false);
  //   setOpenCategories(false);

  //     setOpenProducts(false);
  //   }
  // };
  const handleClickUser = () => {
    setUsers(!users);
    setOpenCategories(false);
    setOpenOrders(false);
    setOrders(false);
    setOpenCategories(false);

    setOpenProducts(false);
    if (drawerOpen) {
    }
  };
  const handleClickOrder = () => {
    setUsers(false);
    setOrders(!orders);
    setOpenCategories(false);
    setOpenOrders(false);
    setOpenProducts(false);
    if (drawerOpen) {
    }
  };
  const handleClickTree = () => {
    if (drawerOpen) {
      setUsers(false);
      setOpenCategories(false);
      setOpenOrders(false);
      setOrders(false);

      setOpenProducts(false);
      setTree(!tree);
    }
  };
  // const handleClickOrder = () => {
  //   if (drawerOpen) {
  //     setOpenOrders(!openOrders);
  //     setUsers(false);
  //     setOpenCategories(false);
  //     setOpenProducts(false);
  //   }
  // };

  // ************************************* hooks ***************************************
  useEffect(() => {
    setDrawer(drawerOpen);
    if (!drawerOpen) {
      setOrders(false);
      setOpenProducts(false);
      setUsers(false);
      setOpenOrders(false);
      setOpenCategories(false);
    }
  }, [drawerOpen]);

  useEffect(() => {
    const getUserFromCookie = cookies.get("userData");
    setUserInfoData(getUserFromCookie);
  }, []);

  useEffect(() => {
    if (userInfoData) {
      const commonMenu = [
        {
          id: 1,
          header: "Products",
          icon: (
            <ShoppingCartIcon fontSize="small" className={classes.iconStyle} />
          ),
          handle: handleClickProducts,
          state: openProducts,
          child: [
            {
              id: 11,
              to: "/dashboard/products",
              name: "Products",
            },
            {
              id: 12,
              to: "/dashboard/create-product",
              name: "Create Product",
            },
          ],
        },
        {
          id: 2,
          header: "Categories",
          icon: (
            <ShoppingCartIcon fontSize="small" className={classes.iconStyle} />
          ),
          handle: handleClickCategories,
          state: openCategories,
          child: [
            {
              id: 21,
              to: "/dashboard/categories",
              name: "Categories",
            },
            {
              id: 22,
              to: "/dashboard/create-category",
              name: "Create Category",
            },
          ],
        },
        {
          id: 4,
          header: "Orders",
          icon: <GroupIcon fontSize="small" className={classes.iconStyle} />,
          handle: handleClickOrder,
          state: orders,
          child: [
            {
              id: 11,
              to: "/dashboard/orders",
              name: "View Orders",
            },
          ],
        },
        // {
        //   id: 2,
        //   header: "Categories",
        //   icon: <CategoryIcon fontSize="small" className={classes.iconStyle} />,
        //   handle: handleClickCategories,
        //   state: openCategories,
        //   child: [
        //     {
        //       id: 11,
        //       to: "/dashboard/CreateCategories",
        //       name: "Create Categories",
        //     },
        //     {
        //       id: 12,
        //       to: "/dashboard/Categories",
        //       name: "All Categories",
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   header: "Users",
        //   icon: <GroupIcon fontSize="small" className={classes.iconStyle} />,
        //   handle: handleClickUser,
        //   state: users,
        //   child: [
        //     {
        //       id: 12,
        //       to: "/dashboard/Users",
        //       name: "View User",
        //     },
        //   ],
        // },
        // {
        //   id: 5,
        //   header: "Tree",
        //   icon: (
        //     <AccountTreeIcon fontSize="small" className={classes.iconStyle} />
        //   ),
        //   handle: handleClickTree,
        //   state: tree,
        //   child: [
        //     {
        //       id: 12,
        //       to: "/dashboard/Recurring",
        //       name: "Recurring",
        //     },
        //   ],
        // },
        // {
        //   id: 4,
        //   header: "Orders",
        //   icon: (
        //     <StorefrontOutlinedIcon
        //       fontSize="small"
        //       className={classes.iconStyle}
        //     />
        //   ),
        //   handle: handleClickOrder,
        //   state: openOrders,
        //   child: [
        //     {
        //       id: 13,
        //       to: "/dashboard/AllOrders",
        //       name: "All Orders",
        //     },
        //     {
        //       id: 14,
        //       to: "/dashboard/PlacedOrders",
        //       name: "Placed",
        //     },
        //     {
        //       id: 15,
        //       to: "/dashboard/VerifiedOrders",
        //       name: "Verified",
        //     },
        //     {
        //       id: 16,
        //       to: "/dashboard/CompleteOrders",
        //       name: "Completed",
        //     },
        //     {
        //       id: 17,
        //       to: "/dashboard/CancelledOrders",
        //       name: "Cancelled",
        //     },
        //     {
        //       id: 18,
        //       to: "/dashboard/PendingOrders",
        //       name: "Pending",
        //     },
        //   ],
        // },
        // {
        // 	id: 3,
        // 	header: 'LiveStream',
        // 	icon: <VideocamIcon />,
        // 	handle: handleClickStream,
        // 	state: openStream,
        // 	child: [
        // 		{
        // 			id: 103,
        // 			to: '/dashboard/VideoCall',
        // 			name: 'Live Stream',
        // 		},
        // 	],
        // },
      ];
      const finalMenu = [...commonMenu];
      console.log(finalMenu);
      setMenu(finalMenu);
    }
  }, [userInfoData, users, openProducts, openCategories, orders]);

  // ************************************* ui ******************************************
  return (
    <div className={classes.root}>
      {/* ************************************* dashboard ****************************************** */}
      {/* <ListItem
        button
        selected={selectedIndex === 0}
        onClick={() => handleListItemClick(0)}
      >
        <ListItemIcon>
          <DashboardIcon fontSize="small" className={classes.iconStyle} />
        </ListItemIcon>
        <Link to="/dashboard/Main" className={classes.linkStyle}>
          <ListItemText
            primary={
              <Typography className={classes.textStyle}>Dasboard</Typography>
            }
          />
        </Link>
      </ListItem> */}

      {/* ************************************* products ****************************************** */}

      {menu.length &&
        menu.map((x) => (
          <div key={x.id}>
            <ListItem
              button
              selected={selectedIndex === x.id}
              onClick={() => {
                x.handle();
                handleListItemClick(x.id);
              }}
            >
              <ListItemIcon>{x.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.textStyle}>
                    {x.header}
                  </Typography>
                }
              />
              {drawer ? (
                <ListItemSecondaryAction>
                  {x.state ? (
                    <ExpandLess fontSize="small" style={{ color: "#ffff" }} />
                  ) : (
                    <ExpandMore fontSize="small" style={{ color: "#ffff" }} />
                  )}
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
            {x.child?.map((item) => (
              <div key={item.id}>
                <Collapse in={x.state} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    <Link to={item.to} className={classes.linkStyle}>
                      <ListItem
                        button
                        className={classes.nested}
                        disableGutters={true}
                        selected={selectedIndex === item.id}
                        onClick={() => {
                          handleListItemClick(item.id);
                        }}
                      >
                        {/* <ListItemIcon>
                          <FiberManualRecordTwoToneIcon
                            style={{ fontSize: ".5em" }}
                            className={classes.iconStyle}
                          />
                        </ListItemIcon> */}
                        <ListItemText
                          primary={
                            <Typography className={classes.textStyle}>
                              {item.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </div>
            ))}
          </div>
        ))}
      <Link to="/dashboard/settings" className={classes.linkStyle}>
        <ListItem
          button
          selected={selectedIndex === 0}
          onClick={() => handleListItemClick(0)}
        >
          <ListItemIcon>
            <DashboardIcon fontSize="small" className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.textStyle}>Settings</Typography>
            }
          />
        </ListItem>
      </Link>
    </div>
  );
};

export default MainListItems;
