import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: { padding: "1rem" },
  headerFont: {
    fontSize: "1.1rem",
    fontFamily: "sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
}));

function OrderSearch({ handleSearch, reset }) {
  const classes = useStyles();
  const [inp, setInp] = useState("");

  return (
    <Grid
      item
      container
      component={Paper}
      elevation={0}
      className={classes.root}
      style={{ justifyContent: "end" }}
    >
      <FormControl
        // className={clsx(classes.margin, classes.textField)}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password">
          Search by Name
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          // type={values.showPassword ? "text" : "password"}
          value={inp || ""}
          onChange={(e) => {
            setInp(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => handleSearch(inp)}
                onMouseDown={(e) => handleSearch(inp)}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                  setInp("");
                  reset();
                }}
                onMouseDown={(e) => {
                  setInp("");
                  reset();
                }}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={120}
        />
      </FormControl>
    </Grid>
  );
}

export default OrderSearch;
