import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TitleCard from "./TitleCard/index";

import Chart from "./Chart/Chart";

import PeopleIcon from "@material-ui/icons/People";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";

import Deposits from "./Deposits/Deposits";
import Orders from "./Orders/Orders";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const card = [
    {
      id: 1,
      icon: <PeopleIcon fontSize="small" />,
      title: "New Members",
      count: "10,456",
    },
    {
      id: 2,
      icon: <CardGiftcardIcon fontSize="small" />,
      title: "New Orders",
      count: "10,123",
    },
    {
      id: 3,
      icon: <AssignmentTurnedInOutlinedIcon fontSize="small" />,
      title: "Complete Orders",
      count: "10,789",
    },
    {
      id: 4,
      icon: <LocalAtmIcon fontSize="small" />,
      title: "Revenue",
      count: "10,500",
    },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={6}>
        {card.map((x) => (
          <Grid item xs={3} key={x.id}>
            {" "}
            <TitleCard title={x.title} icon={x.icon} count={x.count} />
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Chart />
        </Paper>
      </Grid>
    </Grid>
  );
}
