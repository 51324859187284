import {
  ADD_USERS_FAIL,
  ADD_USERS_REQUEST,
  ADD_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  UPDATE_USERS_STATUS_SUCCESS,
  UPDATE_USERS_STATUS_REQUEST,
  UPDATE_USERS_STATUS_FAIL,
} from "../constants/userConstant";

// ************************************ Add USERS *************************************
function AddUsersReducer(state = { users: {} }, action) {
  switch (action.type) {
    case ADD_USERS_REQUEST:
      return { loading: true };
    case ADD_USERS_SUCCESS:
      return { loading: false, users: action.payload, success: true };
    case ADD_USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
// ************************************ Update USERS Status *************************************
function UpdateUsersStatusReducer(state = { users: {} }, action) {
  switch (action.type) {
    case UPDATE_USERS_STATUS_REQUEST:
      return { loading: true };
    case UPDATE_USERS_STATUS_SUCCESS:
      return { loading: false, users: action.payload, success: true };
    case UPDATE_USERS_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
// ************************************ Get USERS *************************************
function GetUsersReducer(state = { users: {} }, action) {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return { loading: true, users: {} };
    case GET_USERS_SUCCESS:
      return { loading: false, users: action.payload, success: true };
    case GET_USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { AddUsersReducer, GetUsersReducer, UpdateUsersStatusReducer };
