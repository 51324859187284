import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#C12B2D";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#C12B2D",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    color: "white",
  },
  name: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  description: {
    width: "50%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  // rate: {
  //   width: "20%",
  //   borderRightColor: borderColor,
  //   borderRightWidth: 1,
  // },
  //   rate: {
  //     width: "15%",
  //     borderRightColor: borderColor,
  //     borderRightWidth: 1,
  //   },
  rate: {
    width: "20%",
  },
});

const InvoiceTableBlankSpace = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.name}>-</Text>
      <Text style={styles.description}>-</Text>
      <Text style={styles.rate}>-</Text>
      {/* <Text style={styles.rate}>-</Text>
            <Text style={styles.amount}>-</Text> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableBlankSpace;
