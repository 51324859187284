import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MapDialog from "../../MapDialog";
import GoogleAutocomplete from "../../GoogleAutocomplete";
import Spacer from "../../Helpers/Spacer";
import { generateStylesInformation } from "../../Helpers/StylesGenerator";

const Blue = "#21242e";
const Orange = "#FFBA60";
const lightBlue = `#1976D2`;
const Grey = "#616161";

const useStyle = makeStyles((theme) => generateStylesInformation(theme));

export default function Information({ handlers }) {
  const {
    onChange,
    // selectedPickupAddress,
    // handleClickOpen,
    // handleMouseDown,
    // selectedPickupValue,
    // open,
    // handleClose,
    // selectedDeliveryAddress,
    // handleClickOpenDelivery,
    // selectedDeliveryValue,
    // openDelivery,
    // handleCloseDelivery,
    setSelectedPickupLocation,
    setSelectedPickupAddress,
    setSelectedDeliveryLocation,
    setSelectedDeliveryAddress,
    formData,
    validationHelper,
  } = handlers;

  const {
    name,
    email,
    mobile,
    cargo,
    cargoHours,
    numberOfWorkers,
    numberOfStairsFirstAddress,
    numberOfStairsSecondAddress,
  } = formData;
  const {
    nameHelper,
    emailHelper,
    mobileHelper,
    cargoHelper,
    cargoHoursHelper,
    numberOfWorkersHelper,
    numberOfStairsFirstAddressHelper,
    numberOfStairsSecondAddressHelper,
  } = validationHelper;

  const classes = useStyle();
  const matchesSm = useMediaQuery("(min-width:600px)");

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.inputContainer}
      >
        <Grid item container className={classes.flexChangeOnResponsive}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Contact Information
            </Typography>
          </Grid>
          <Spacer margin={matchesSm ? 25 : 10} />
          <Grid
            item
            container
            xs={12}
            sm={4}
            className={classes.marginRightOnResponsive}
          >
            <TextField
              color="primary"
              id="name"
              variant="outlined"
              placeholder="-"
              label="Name"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              size="small"
              name="name"
              value={name || ""}
              error={nameHelper.length !== 0}
              helperText={nameHelper}
              onChange={onChange}
            />
          </Grid>
          <Spacer margin={10} />
          <Grid
            item
            container
            xs={12}
            sm={4}
            className={classes.marginRightOnResponsive}
          >
            <TextField
              color="primary"
              id="email"
              variant="outlined"
              placeholder="-"
              label="Email"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              size="small"
              name="email"
              value={email || ""}
              error={emailHelper.length !== 0}
              helperText={emailHelper}
              onChange={onChange}
            />
          </Grid>
          <Spacer margin={10} />
          <Grid item container xs={12} sm={4}>
            <TextField
              color="primary"
              id="mobile"
              variant="outlined"
              placeholder="-"
              label="Mobile"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              size="small"
              name="mobile"
              value={mobile || ""}
              error={mobileHelper.length !== 0}
              helperText={mobileHelper}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Spacer margin={10} />
          <Grid item xs={12}>
            <Divider style={{ backgroundColor: "rgb(255 255 255 / 23%)" }} />
          </Grid>
          <Spacer margin={10} />
        </Grid>
        <Grid item container className={classes.flexChangeOnResponsive}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Move Information
            </Typography>
          </Grid>
          <Spacer margin={matchesSm ? 25 : 10} />
          <Grid
            item
            container
            xs={12}
            sm={6}
            className={classes.marginRightOnResponsive}
          >
            <GoogleAutocomplete
              handlers={{
                setLocation: setSelectedPickupLocation,
                setValueAddress: setSelectedPickupAddress,
                label: "Pickup Address",
              }}
            />
            {/* <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel shrink htmlFor="outlined-adornment-password">
                Pickup Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={selectedPickupAddress}
                onClick={handleClickOpen}
                notched
                readOnly
                placeholder="-"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickOpen}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={150}
              />
            </FormControl> */}
            {/* <MapDialog
              selectedValue={selectedPickupValue ?? {}}
              open={open}
              onClose={handleClose}
            /> */}
          </Grid>
          <Spacer margin={10} />
          <Grid item container xs={12} sm={6}>
            <GoogleAutocomplete
              handlers={{
                setLocation: setSelectedDeliveryLocation,
                setValueAddress: setSelectedDeliveryAddress,
                label: "Delivery Address",
              }}
            />
            {/* <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel shrink htmlFor="outlined-adornment-password">
                Delivery Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={selectedDeliveryAddress}
                onClick={handleClickOpen}
                notched
                readOnly
                placeholder="-"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickOpenDelivery}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={160}
              />
            </FormControl>
            <MapDialog
              selectedValue={selectedDeliveryValue ?? {}}
              open={openDelivery}
              onClose={handleCloseDelivery}
            /> */}
          </Grid>
          <Spacer margin={matchesSm ? 30 : 10} />
          <Grid
            item
            container
            xs={12}
            sm={6}
            className={classes.marginRightOnResponsive}
          >
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel shrink id="demo-simple-select-outlined-label-pd">
                Stairs in Pickup Address
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label-pd"
                id="demo-simple-select-outlined"
                name="numberOfStairsFirstAddress"
                value={numberOfStairsFirstAddress.toString() || ""}
                error={numberOfStairsFirstAddressHelper.length !== 0}
                helperText={numberOfStairsFirstAddress}
                onChange={onChange}
                displayEmpty
                placeholder="-"
                notched
                fullWidth
                labelWidth={230}
              >
                <MenuItem value={0}>No Stairs / Lift Available</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={-1}>External Cargo Required</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Spacer margin={10} />
          <Grid item container xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel shrink id="demo-simple-select-outlined-label-da">
                Stairs in Delivery Address
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label-da"
                id="demo-simple-select-outlined"
                name="numberOfStairsSecondAddress"
                value={numberOfStairsSecondAddress.toString() || ""}
                error={numberOfStairsSecondAddressHelper.length !== 0}
                helperText={numberOfStairsSecondAddress}
                onChange={onChange}
                displayEmpty
                placeholder="-"
                notched
                fullWidth
                labelWidth={240}
              >
                <MenuItem value={0}>No Stairs / Lift Available</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={-1}>External Cargo Required</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Spacer margin={matchesSm ? 30 : 10} />
          <Grid
            item
            container
            xs={12}
            sm={6}
            className={classes.marginRightOnResponsive}
          >
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel shrink id="demo-simple-select-outlined-label">
                No. of Workers
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="numberOfWorkers"
                value={numberOfWorkers || 0}
                error={numberOfWorkersHelper.length !== 0}
                helperText={numberOfWorkersHelper}
                onChange={onChange}
                displayEmpty
                placeholder="-"
                notched
                fullWidth
                labelWidth={150}
              >
                <MenuItem value={0}>
                  <em>-</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>more than 4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Spacer margin={matchesSm ? 30 : 10} />

          {/* <Grid
            item
            container
            xs={12}
            sm={4}
            className={classes.marginRightOnResponsive}
          >
            <TextField
              id="dateOfMove"
              name="dateOfMove"
              variant="outlined"
              size="small"
              fullWidth
              label="Date of Move"
              type="datetime-local"
              defaultValue={Date.now()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Spacer margin={matchesSm ? 30 : 10} /> */}

          <Grid item container xs={12} sm={6}>
            {/* <FormControl component="fieldset">
              <FormLabel component="legend" style={{ fontSize: "16px" }}>
                Do you need Cargo Lifter?
              </FormLabel>
              <RadioGroup
                row
                aria-label="cargo"
                name="cargo"
                value={cargo || ""}
                error={cargoHelper.length !== 0}
                helperText={cargoHelper}
                onChange={onChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography variant="body2" gutterBottom>
                      Yes
                    </Typography>
                  }
                  style={{ fontSize: "14px" }}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography variant="body2" gutterBottom>
                      No
                    </Typography>
                  }
                  style={{ fontSize: "14px !important" }}
                />
              </RadioGroup>
            </FormControl> */}
            {/* <TextField
              color="primary"
              id="cargoHours"
              variant="outlined"
              placeholder="-"
              label="Cargo Hours"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              fullWidth
              size="small"
              disabled={cargo === "yes" ? false : true}
              name="cargoHours"
              value={cargoHours || ""}
              error={cargoHoursHelper.length !== 0}
              helperText={cargoHoursHelper}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  onChange(e);
                }
              }}
            /> */}
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel shrink id="demo-simple-select-outlined-label-cargo">
                Cargo Hours
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label-cargo"
                id="demo-simple-select-outlined"
                name="cargoHours"
                value={cargoHours || ""}
                error={cargoHoursHelper.length !== 0}
                helperText={cargoHoursHelper}
                onChange={onChange}
                displayEmpty
                disabled={cargo === "yes" ? false : true}
                placeholder="-"
                notched
                fullWidth
                labelWidth={150}
              >
                <MenuItem value={0}>
                  <em>-</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
