import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_REQUEST,
} from "../constants/settingConstant";

function UpdateSettingsReducer(state = { message: {} }, action) {
  switch (action.type) {
    case UPDATE_SETTINGS_REQUEST:
      return { loading: true };
    case UPDATE_SETTINGS_SUCCESS:
      return { loading: false, message: action.payload, success: true };
    case UPDATE_SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function GetSettingsReducer(state = { settings: "" }, action) {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return { loading: true, settings: "" };
    case GET_SETTINGS_SUCCESS:
      return { loading: false, settings: action.payload, success: true };
    case GET_SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { UpdateSettingsReducer, GetSettingsReducer };
