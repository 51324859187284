import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import "../style/style.css";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: 12,
  },

  divider: {
    // Theme Color, or use css color in quote
    background: "#0000",
  },
  padding: {
    padding: ".5rem",
  },

  selectWidth: {
    width: "100%",
    marginTop: "7px",
    marginLeft: "10px",
    marginRight: "1px",
  },

  dropZone: {
    height: "10px",
    fullWidth: "true",
  },
  previewContainer: {
    container: "true",
    width: "100%",
    height: "100%",
  },
  preview: {
    //width: '100%',
    //height: '100%',
    //item: 'true',
    xs: "12",
  },
  previewImg: {
    //height: '100%',
    //width: '100%',
  },
}));

function Card({ formValidation, formData, setformData }) {
  const classes = useStyles();

  // ********************** state***************************
  const { name, email, password, agencyName, agencyDescription } = formData;

  const {
    nameHelper,
    emailHelper,
    passwordHelper,
    agencyNameHelper,
    agencyDescriptionHelper,
  } = formValidation;

  // ************************** handle Change **************************
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Grid item container component={Paper} elevation={1}>
      <Grid item container alignItems="center" style={{ padding: ".7rem" }}>
        <Typography variant="h2">Add New Agency User</Typography>
      </Grid>
      <Divider style={{ width: "100%" }} />

      {/* Row 1 */}
      <Grid item container direction="row" style={{ paddingTop: "1rem" }}>
        <Grid item container sm className={classes.padding}>
          <TextField
            id="name"
            name="name"
            value={name}
            InputProps={{ style: { fontSize: 12 } }}
            fullWidth
            size="small"
            label="Name"
            variant="outlined"
            onChange={onChange}
            error={nameHelper?.length >= 1}
            helperText={nameHelper}
          />
        </Grid>
        <Grid item container sm className={classes.padding}>
          <TextField
            id="email"
            name="email"
            value={email}
            InputProps={{ style: { fontSize: 12 } }}
            fullWidth
            size="small"
            label="Email"
            variant="outlined"
            onChange={onChange}
            error={emailHelper?.length >= 1}
            helperText={emailHelper}
          />
        </Grid>
      </Grid>
      {/* Row 2 */}
      <Grid item container direction="row">
        <Grid item container sm className={classes.padding}>
          <TextField
            value={agencyName}
            id="agencyName"
            name="agencyName"
            InputProps={{ style: { fontSize: 12 } }}
            fullWidth
            size="small"
            label="Agency Name"
            variant="outlined"
            onChange={onChange}
            error={agencyNameHelper?.length >= 1}
            helperText={agencyNameHelper}
          />
        </Grid>
        <Grid item container sm className={classes.padding}>
          <TextField
            id="agencyDescription"
            name="agencyDescription"
            value={agencyDescription}
            InputProps={{ style: { fontSize: 12 } }}
            fullWidth
            size="small"
            label="Agency Description"
            variant="outlined"
            onChange={onChange}
            error={agencyDescriptionHelper?.length >= 1}
            helperText={agencyDescriptionHelper}
          />
        </Grid>
      </Grid>
      {/* Row 3 */}
      <Grid item container direction="row">
        <Grid item container sm className={classes.padding}>
          <TextField
            id="password"
            name="password"
            value={password}
            InputProps={{ style: { fontSize: 12 } }}
            fullWidth
            size="small"
            label="Password"
            variant="outlined"
            onChange={onChange}
            error={passwordHelper?.length >= 1}
            helperText={passwordHelper}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Card;
