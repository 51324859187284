/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeleteIcon from "@material-ui/icons/Delete";
import cookerImg from "../../../../asset/image/Cooker.png";
import ovenImg from "../../../../asset/image/Oven.png";
import microwaveImg from "../../../../asset/image/Microwave.png";
import equippedKitchenImg from "../../../../asset/image/EquippedKitchen.png";
import tvImg from "../../../../asset/image/MeubleTV.png";
import bookShelveImg from "../../../../asset/image/Bibliotheque.png";
import chairImg from "../../../../asset/image/Chaise.png";
import pianoImg from "../../../../asset/image/Piano.png";
import TabPanel from "../../TabPanel";
import Spacer from "../../Helpers/Spacer";
import Alert from "../../Helpers/Alert";
import { generateStylesCalculateCargo } from "../../Helpers/StylesGenerator";
import instance from "../../../../Config/axios";
const prettyMetric = require("pretty-metric");

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyle = makeStyles((theme) => generateStylesCalculateCargo(theme));

export default function CalculateCargo({ handlers }) {
  const {
    value,
    handleChange,
    selectedItems,
    handleSetSelectedItems,
    setOpenSnackbar,
    handleDeleteSelectedItems,
    addressDistance,
    selectedItemsVolume,
  } = handlers;
  const matchesSm = useMediaQuery("(min-width:600px)");
  const classes = useStyle();

  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const { data } = await instance.get("/categories/get-all-listed");
      console.log(data, "data");
      setCategories(data.data.categories);
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  // const categories = [
  //   {
  //     _id: "1",
  //     name: "Lounge",
  //     products: [
  //       {
  //         _id: "1",
  //         img: pianoImg,
  //         name: "piano",
  //         volume: "3",
  //       },
  //       {
  //         _id: "89",
  //         img: pianoImg,
  //         name: "piano",
  //         volume: "3",
  //       },
  //       {
  //         _id: "99",
  //         img: pianoImg,
  //         name: "piano",
  //         volume: "3",
  //       },
  //       {
  //         _id: "2",
  //         img: chairImg,
  //         name: "chair",
  //         volume: "3",
  //       },
  //       {
  //         _id: "3",
  //         img: bookShelveImg,
  //         name: "book shelve",
  //         volume: "3",
  //       },
  //       {
  //         _id: "4",
  //         img: tvImg,
  //         name: "TV table",
  //         volume: "3",
  //       },
  //     ],
  //   },
  // ];
  return (
    <>
      <Grid
        style={{ height: matchesSm ? "700px" : "auto" }}
        item
        container
        direction="row"
        // justify="center"
        // alignItems="center"
      >
        <Grid item container xs={12} sm={9}>
          <Grid item xs={12} sm={4}>
            <Tabs
              orientation={matchesSm ? "vertical" : "horizontal"}
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {categories &&
                categories.map((category, index) => {
                  return (
                    <Tab
                      // icon={<PhoneIcon />}
                      key={category._id}
                      label={category.name}
                      {...a11yProps(index)}
                    />
                  );
                })}
            </Tabs>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            style={{ height: "700px", overflowY: "scroll", padding: "10px" }}
          >
            {categories &&
              categories.map((category, index) => {
                return (
                  <TabPanel
                    key={category._id}
                    value={value}
                    index={index}
                    categoryItems={category.products}
                    selectedItems={selectedItems ?? []}
                    handleSetSelectedItems={handleSetSelectedItems}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                );
              })}
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          xs={12}
          sm={3}
          alignContent="space-between"
          //   style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Grid item container direction="column">
            <Grid item xs={12}>
              <Box
                style={{
                  backgroundColor: "#313F4C",
                  padding: "0.5em",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{ color: "white", textAlign: "center" }}
                  variant="body2"
                  gutterBottom
                >
                  Your Items: {selectedItems ? selectedItems.length : "0"}
                </Typography>
              </Box>
              {!selectedItems.length && (
                <h5 style={{ color: "black", textAlign: "center" }}>
                  Nothing found
                </h5>
              )}
              <Scrollbars style={{ height: 480, marginBottom: "10px" }}>
                <List dense={true}>
                  {selectedItems?.map((item) => {
                    return (
                      <ListItem key={item._id}>
                        {/* <ListItemAvatar>
                          <Avatar style={{ borderRadius: "5px" }}>
                            <img src={item.img} style={{ width: "40px" }} />
                          </Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                          style={{ color: "#21242e" }}
                          primary={
                            <Typography
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                              variant="button"
                              gutterBottom
                            >
                              {item.name} | {item.volume} m<sup>3</sup>
                            </Typography>
                          }
                          secondary={"Quantity: " + item.quantity}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteSelectedItems(item._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Scrollbars>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item xs={12}>
              <Box
                style={{
                  backgroundColor: "#f2f2f2",
                  padding: "1em",
                }}
              >
                {addressDistance && (
                  <>
                    <Typography
                      variant="button"
                      style={{ color: "#21242e", textTransform: "capitalize" }}
                      gutterBottom
                    >
                      {/* Total Distance: {(addressDistance / 1000).toFixed(2)} Km Total */}
                      Total Distance: {prettyMetric(addressDistance).humanize()}
                    </Typography>
                    <br />
                  </>
                )}
                <Typography
                  variant="button"
                  style={{ color: "#21242e", textTransform: "capitalize" }}
                  gutterBottom
                >
                  Total Volume: {selectedItemsVolume.toFixed(2)} m<sup>3</sup>
                </Typography>
              </Box>
              {/* <Button
                variant="contained"
                fullWidth
                disableElevation
                color="secondary"
                onClick={() => {
                  setOpenSnackbar({
                    open: true,
                    severity: "success",
                    message: "Thank you, You will be notified soon",
                  });
                }}
                style={{ backgroundColor: "#c12b2d", borderRadius: 0 }}
              >
                Get a quote
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
