import React, { useState, useEffect } from "react";
// @material-ui/core components
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import DoneIcon from "@material-ui/icons/Done";
// core components
import {
  Grid,
  IconButton,
  Button,
  TableContainer,
  TablePagination,
  Tooltip,
  makeStyles,
  Chip,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "14px",
    fontWeight: "550",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  tabelCellFont: {
    fontSize: "12px",
  },
  PName: {
    fontSize: "12px",
    fontWeight: "550",
    textTransform: "capitalize",
  },
}));

const TableRowCustom = (props) => {
  const {
    tableHead,
    handleDelete,
    row,
    index,
    handleBlockUser,
    classes,
    handleUpdate,
  } = props;
  const [innerRow, setInnerRow] = useState();
  const handleInnerRow = (valueInput) => {
    setInnerRow({ ...valueInput });
  };
  // useEffect(() => {
  //   setInnerRow(row);
  // }, []);

  useEffect(() => {
    setInnerRow(row);
  }, []);
  console.log(row, "row is here");
  console.log(innerRow, "innerrow");
  useEffect(() => {
    console.log("kkkk");
  }, [innerRow]);
  return (
    <>
      {innerRow && (
        <TableRow key={index} hover role="action" tabIndex={-1}>
          {tableHead.map((column, key) => {
            const value = innerRow[column.id];
            // handleValue(row[column.id]);

            switch (column.id) {
              case "action":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        aria-label="Delete"
                        color="primary"
                        onClick={() => {
                          handleDelete(innerRow._id);
                        }}
                      >
                        <DeleteSweepOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Edit" arrow>
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    // onClick={() => {
                                    // 	setHandCloseDailog(true);
                                    // 	ActionFunction &&
                                    // 	dispatch(ActionFunction(row.id));
                                    // 	props.setID && props.setID(row.id);
                                    // 	console.log(row.id, 'row id');
                                    // }}
                                  >
                                    <CreateIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip> */}
                  </TableCell>
                );
              case "settingAction":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Tooltip title="Update" arrow>
                      <Button
                        aria-label="Update"
                        color="primary"
                        onClick={() => {
                          handleUpdate(innerRow._id, innerRow.value);
                        }}
                      >
                        Update
                      </Button>
                    </Tooltip>
                    {/* <Tooltip title="Edit" arrow>
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    // onClick={() => {
                                    // 	setHandCloseDailog(true);
                                    // 	ActionFunction &&
                                    // 	dispatch(ActionFunction(row.id));
                                    // 	props.setID && props.setID(row.id);
                                    // 	console.log(row.id, 'row id');
                                    // }}
                                  >
                                    <CreateIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip> */}
                  </TableCell>
                );

              case "actionBlockUser":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Tooltip title="Block/Unblock" arrow>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={innerRow.checked}
                            onChange={(e) =>
                              handleBlockUser(innerRow._id, !innerRow.checked)
                            }
                          />
                        }
                      />
                      {/* <Button
                                    aria-label="Block"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      console.log("i am running", row);
                                      handleBlockUser(row._id);
                                      // setHandCloseDailog(true);
                                      // ActionFunction &&
                                      // dispatch(ActionFunction(row.id));
                                      // props.setID && props.setID(row.id);
                                      // console.log(row.id, 'row id');
                                    }}
                                  >
                                    {row.isBlocked == 1 ? "Unblock" : "Block"}
                                  </Button> */}
                    </Tooltip>
                  </TableCell>
                );

              case "image":
                return (
                  <TableCell key={key} align="center">
                    {
                      <img
                        src={`${process.env.REACT_APP_HOST}${value}`}
                        width="70px"
                        height="50px"
                        style={{ objectFit: "cover" }}
                        alt={value?.title}
                      />
                    }
                  </TableCell>
                );

              case "createdAt":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {value ? new Date(value).toLocaleDateString() : "-"}
                  </TableCell>
                );

              case "variation":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography className={classes.tabelCellFont}>
                      Name: {value[0]?.name}
                      <br />
                      Price: {value[0]?.variance?.price}
                      <br />
                      Stock: {value[0]?.variance?.stock}
                    </Typography>
                  </TableCell>
                );

              case "status":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {value === "delisted" ? (
                      <Chip
                        // onDelete={() => handleLive()}
                        color="secondary"
                        label={
                          <Typography
                            style={{
                              fontSize: "11px",
                              color: "#fff",
                            }}
                          >
                            Delisted
                          </Typography>
                        }
                        size="small"
                      />
                    ) : (
                      <Chip
                        color="secondary"
                        label="Live"
                        size="small"
                        // deleteIcon={<DoneIcon />}
                        // onDelete={() => handleDelisted()}
                      />
                    )}
                  </TableCell>
                );

              case "isBlocked":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography className={classes.PName}>
                      {value == 1 ? "Yes" : "No"}
                    </Typography>
                  </TableCell>
                );
              case "name":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography className={classes.PName}>
                      {value ? value : "-"}
                    </Typography>
                  </TableCell>
                );

              case "value":
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <TextField
                      // id="standard-helperText"
                      size="small"
                      variant="outlined"
                      name="value"
                      value={value || ""}
                      style={{
                        marginRight: "10px",
                        fontSize: "10",
                      }}
                      InputProps={{ style: { fontSize: "medium" } }}
                      onChange={(event) => {
                        console.log(event.target.value, "event");
                        // value = event.target.value;
                        innerRow[column.id] = event.target.value;
                        console.log(innerRow, "inner row updated");
                        handleInnerRow(innerRow);
                      }}
                    />
                  </TableCell>
                );

              default:
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography className={classes.tabelCellFont}>
                      {value ? value : "-"}
                    </Typography>
                  </TableCell>
                );
            }
          })}
        </TableRow>
      )}
    </>
  );
};

function CustomTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    Limit,
    // Page,
    // ActionFunction,
    handleLive,
    handleDelisted,
    handleBlockUser,
    // tableHeaderColor,
    // DeleteDailog,
    // Delete,
    // setHandCloseDailog,
    handleDelete,
    handleUpdate,
  } = props;

  //   *****************************************************************************************
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Limit ? Limit : 10);
  const [checked, setChecked] = React.useState(false);
  const [innerTableData, setInnerTableData] = React.useState([]);

  //**************************************Handle Change Page*********************************** */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(newPage);
    console.log(
      tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const toggleChecked = () => {
    setTimeout(() => {
      setChecked((prev) => !prev);
    }, 2000);
  };

  useEffect(() => {
    setInnerTableData(tableData);
  }, []);
  // useEffect(() => {
  //   tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // }, [page]);
  // *******************************************************************************************

  //   ****************************************UI********************************************
  return (
    <Grid item container component={Paper} variant="outlined">
      <TableContainer className={classes.container}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          {tableHead !== undefined ? (
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell align="center" key={key}>
                      <Typography className={classes.header}>
                        {prop.label}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {innerTableData &&
              innerTableData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  if (row) {
                    // return <h1>{row._id}</h1>;
                    return (
                      <TableRowCustom
                        key={row._id}
                        row={row}
                        index={index}
                        tableHead={tableHead}
                        handleDelete={handleDelete}
                        handleBlockUser={handleBlockUser}
                        classes={classes}
                        handleUpdate={handleUpdate}
                      />
                    );
                  }
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ borderBottom: "#000000" }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

export default React.memo(CustomTable);
